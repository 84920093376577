import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { processMediaUrl } from '../../_helpers/utils'

function ConversationHeader({ channelDetails, setViewList, cssChange }) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                padding: '1rem',
                gap: '0.5rem',
                borderBottom: '1px solid #E4ECF8',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '0.65rem',
                }}
            >
                <span
                    className="icon-remove"
                    onClick={() => {
                        setViewList(e => !e)
                        cssChange()
                    }}
                >
                    <IoIosArrowBack size={24} />
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.938rem',
                    }}
                >
                    <span style={{ position: 'relative', flexShrink: 0 }}>
                        <img
                            alt=""
                            src={channelDetails.property.picture ? processMediaUrl(channelDetails.property.picture) : '/images/user.png'}
                            style={{
                                height: '2.75rem',
                                width: '2.75rem',
                                borderRadius: '9999px',
                                objectFit: 'cover',
                                flexShrink: 0,
                            }}
                        />
                    </span>
                    <div
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            color: '#23426e',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <p
                                className="channel-name"
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {channelDetails.property.name}
                            </p>
                            {/* <span
                                style={{
                                    height: '0.5rem',
                                    width: '0.5rem',
                                    display: 'block',
                                    backgroundColor: '#06d6a0',
                                    borderRadius: '9999px',
                                    marginLeft: '0.5rem',
                                    marginBottom: '0.25rem',
                                }}
                            ></span> */}
                        </div>
                        <div>
                            <p
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    margin: '5px 0',
                                }}
                            >
                                {channelDetails.property.user}
                            </p>
                            <p
                                style={{
                                    fontSize: '0.8rem',
                                    fontWeight: 500,
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {channelDetails.property.reservationId}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    color: '#6b7280',
                }}
            >
                {/* <AiFillHeart size={20} /> */}
            </span>
        </div>
    )
}

export default ConversationHeader
