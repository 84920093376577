import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const UnlistPropertyModal = ({ isOpen, onClose, onConfirm ,status}) => {
  const [reason, setReason] = useState('');

  const handleConfirm = () => {
    if (reason.trim()) {
      onConfirm(reason,status);
      onClose();
    } else {
      alert('Please enter a reason to unlist your property.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Unlist Property Modal"
      shouldCloseOnOverlayClick={false}
      style={{
        overlay:{
            zIndex:5,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          maxWidth:500,
          transform: 'translate(-50%, -50%)',
          minWidth:'30%',
          
        },
      }}
    >
    <div className=''>
      <h4>Unlist Property</h4>
      <label htmlFor="reason" className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold">
        Enter reason to unlist your property*
      </label>
      <textarea
        id="reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
        placeholder="Enter your reason here..."
      />
      <div className="mt-4 tw-flex tw-justify-end tw-gap-4">
        <button onClick={onClose} 
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary  tw-rounded-md tw-text-white">
          Cancel
        </button>
        <button onClick={handleConfirm} 
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary tw-rounded-md tw-text-white"
        >
           Unlist
        </button>
      </div>
      </div>
    </Modal>
  );
};

export default UnlistPropertyModal;
