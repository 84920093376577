import React, { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";

import constant from "../../../_config/constant";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js";
import { authHeader } from "../../../_helpers";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
var Modal = require("react-bootstrap-modal");

export const PayoutMethod = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  requesting,
  hostDetail,
  setDetail,
  saveAsDraft,
  draftRequesting,
  setIspaymentConfirm,
  isPaymentConfirm
}) => {
  const [addedBankAccount, setAddedBankAccount] = useState([]);

  const userId = JSON.parse(localStorage.getItem(constant.DATA_KEY)).id;

  const history = useHistory();
  const [hideButtons, setHideButtons] = useState(false);
  //Stripe Code

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await fetch(constant.apiUrl + "/stripe-account-create", {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json" },
      });

      if (!response.ok) {
        // Handle errors on the client side here
        const { error } = await response.json();
        console.error("An error occurred: ", error);
        // document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const data = await response.json();
        //document.querySelector('#error').setAttribute('hidden', '');
        return data.data.client_secret;
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      //replace this with env var while pushing code note-deploy
      publishableKey:  process.env.REACT_APP_STRIPE_API_KEY,// "pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz",",
      fetchClientSecret: fetchClientSecret,
    });
  });
  //Stripe Code
  const handleNextStep = async(e) => {
    e.preventDefault();
    e.stopPropagation();
    //hostDetail.payoutVerification = 1 ;
    // window.scrollTo({ top: 0, behavior: "smooth" });
    // onNext();

    // update user flag 
    const response = await fetch(constant.apiUrl+'/stripe-account-update-payout', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
    if (!response.ok) {
      // Handle errors on the client side here
      const {error} = await response.json();
      console.error('An error occurred: ', error);
      // document.querySelector('#error').removeAttribute('hidden');
      return undefined;
    } else {
        
        //hostDetail.payoutVerification = 1 ; 
        setIspaymentConfirm(true)
        
        localStorage.setItem("hostDetailPayout", 1);
        console.log("hostDetailPayout payout after udpate  "+localStorage.getItem("hostDetailPayout"));
        window.scrollTo({ top: 0, behavior: "smooth" });
        onNext();
        
    }
  };

  const updateStripeUserAccout = async () => {
    // update user flag 
    const response = await fetch(constant.apiUrl+'/stripe-account-update-payout', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
    if (!response.ok) {
      // Handle errors on the client side here
      const {error} = await response.json();
      console.error('An error occurred: ', error);
      // document.querySelector('#error').removeAttribute('hidden');
      return undefined;
    } else {
        
        //hostDetail.payoutVerification = 1 ; 
        setIspaymentConfirm(true)
        
        localStorage.setItem("hostDetailPayout", 1);
        console.log("hostDetailPayout payout after udpate  "+localStorage.getItem("hostDetailPayout"));
        window.scrollTo({ top: 0, behavior: "smooth" });
        onNext();
        
    }
  };
  return (
    <div className={className}>
      <div className=" ">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            10
          </span>{" "}
          Payout Method
        </h4>
        <div className="row">
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
              onExit={() => {
                console.log("The account has exited onboarding");
                setHideButtons(true);
                updateStripeUserAccout();
                setIspaymentConfirm(true)
                // setTimeout(() => {
                //   updateStripeUserAccout(); // Reloads the page
                // }, 500); // 3000 milliseconds (3 seconds)
              }}
              // Optional: make sure to follow our policy instructions above
              // fullTermsOfServiceUrl="{{URL}}"
              // recipientTermsOfServiceUrl="{{URL}}"
              // privacyPolicyUrl="{{URL}}"
              // skipTermsOfServiceCollection={false}
              // collectionOptions={{
              //   fields: 'eventually_due',
              //   futureRequirements: 'include',
              // }}
              // onStepChange={(stepChange) => {
              //   console.log(`User entered: ${stepChange.step}`);
              // }}
            />
          </ConnectComponentsProvider>
        </div>
      </div>
      <div className=" payout-property-margin">
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>

        <button
          disabled={!isPaymentConfirm}
          type="submit"
          className="btn btn-secondary mx-2 tw-mb-2"
          onClick={handleNextStep}
        >
          Next
        </button>
        {/* <button
          onClick={onNext}
          type="button"
          className="btn btn-secondary mx-2 tw-mb-2"
          disabled={requesting}
        >
          {requesting ? "Adding ..." : "Review & Publish"}
        </button> */}
        {!hideButtons && (
          <>
            <button
              type="button"
              disabled={draftRequesting}
              onClick={saveAsDraft}
              className="btn btn-secondary mx-2 tw-mb-2"
            >
              {draftRequesting ? "Adding ..." : "Save as Draft"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
