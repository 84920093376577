import React, { useEffect, useState } from 'react'
import CustomModal from '../../components/ReactModal'
import { userService, bankService } from '../../_services'
import { toast } from 'react-toastify'
import { saveData } from '../../_helpers'
import { FaTrash } from 'react-icons/fa'
import { MdModeEdit } from 'react-icons/md'
import AlertBox from '../AlertBox'
import { useHistory, Link } from 'react-router-dom'

function PayoutVerificationModal({ onClose, bankAccountId, setRefreshKey }) {
    const [firstDeposit, setFirstDeposit] = useState('')
    const [secondDeposit, setSecondDeposit] = useState('')
    const [depositeVerifing, setDepositeVerifing] = useState(false)
    const re = /^[0-9\b]+$/

    const setValue = (tag, value) => {
        const re = /^[0-9\b]+$/
        // if value is not blank, then test the regex
        // if (value === '' || re.test(value)) {
        //   if(tag === 'firstDeposite'){
        //     setFirstDeposit(value);
        //   }else{
        //     setfSecondDeposit(value)
        //   }
        // }
    }

    function validateInput(val, fun) {
        if (val < 0) {
            return fun(0)
        } else {
            fun(parseInt(Math.round(val)))
        }
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (firstDeposit <= 0 || firstDeposit >= 101) {
            return toast.error('First deposit must be less than 100 cents and greater than 1 cent')
        }

        if (secondDeposit <= 0 || secondDeposit >= 101) {
            return toast.error('Second deposit must be less than 100 cents and greater than 1 cent')
        }

        const data = {
            bankAccountId,
            firstDeposit: firstDeposit,
            secondDeposit: secondDeposit,
        }
        setDepositeVerifing(true)
        bankService.verifyBank(data).then(
            objS => {
                if (objS.status) {
                    toast.success('Account verified.')
                    onClose()
                } else {
                    toast.error(objS.message)
                }
                setRefreshKey(oldKey => oldKey + 1)
                setDepositeVerifing(false)
            },
            error => {
                setDepositeVerifing(false)
            }
        )
    }
    return (
        <div className="tw-bg-white tw-w-[95%] md:tw-w-[65%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2">
            <h1 className="tw-text-lg  tw-mb-2">Verify Bank Account</h1>
            <p className="tw-text-base  tw-mb-4">
                Stripe automatically sends two small deposits which appear on your online statement in 1-2 business days. Please confirm the
                micro-deposits to verify your bank account connection.
            </p>

            <form className=" tw-flex tw-flex-col tw-gap-4" onSubmit={handleSubmit}>
                <div>
                    <label className="form-label">First Deposit</label>
                    <input
                        value={firstDeposit}
                        onChange={e => {
                            validateInput(e.target.value, setFirstDeposit)
                        }}
                        type="number"
                        className="form-control"
                        name="firstDeposit"
                        placeholder=""
                        required
                    />
                </div>
                <div>
                    <label className="form-label">Second Deposit</label>
                    <input
                        value={secondDeposit}
                        onChange={e => {
                            validateInput(e.target.value, setSecondDeposit)
                        }}
                        type="number"
                        className="form-control"
                        name="secondDeposit"
                        placeholder=""
                        required
                    />
                </div>
                <p className="tw-text-sm  tw-my-2">
                    For Example: if you receive a deposit of .06 and .15 then your deposit values will be will be entered as 6 and 15 above.
                </p>
                <div className="tw-flex tw-justify-end tw-gap-4">
                    <button type="reset" onClick={onClose} className="btn btn-primary">
                        Cancel
                    </button>
                    <button disabled={depositeVerifing} type="submit" className="btn btn-primary">
                        {depositeVerifing ? 'Please wait ...' : 'Verify'}
                    </button>
                </div>
            </form>
        </div>
    )
}

function BankAccountCard({ accountData, isLast, handleDelete, setbankListRefreshkey }) {
    const [edit, setEdit] = useState(false)
    const [verify, setVerify] = useState(false)
    const [primaryRequest, setPrimaryRequest] = useState(false)
    const toggleEdit = () => setEdit(!edit)
    const toggleVerify = () => setVerify(!verify)
    const [alert, setAlert] = useState(false)
    const renderAccountCardChip = type => {
        switch (type) {
            case 'new': {
                return <span className="tw-w-max tw-px-2 tw-py-1 tw-rounded tw-bg-blue-500 tw-boder-0 tw-border tw-text-white">New</span>
            }

            default: {
                return <span className="tw-w-max tw-px-2 tw-py-1 tw-rounded tw-bg-green-600 tw-boder-0 tw-border tw-text-white">Active</span>
            }
        }
    }

    const accountStatusCard = type => {
        switch (type) {
            case 'verified': {
                return (
                    <span className={`tw-w-max tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-green-600 tw-boder-0 tw-border tw-text-white`}>
                        Verified
                    </span>
                )
            }

            case 'verification_failed': {
                return (
                    <span className={`tw-w-max tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-red-600 tw-boder-0 tw-border tw-text-white`}>
                        Verification Failed
                    </span>
                )
            }

            default: {
                return (
                    <span
                        onClick={toggleVerify}
                        className={`tw-w-max tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-primary tw-boder-0 tw-border tw-text-white`}
                    >
                        Verify Now
                    </span>
                )
            }
        }
    }

    const editBankAccount = async (data, setRequesting) => {
        try {
            setRequesting(true)
            const res = await bankService.updateBank({
                bankAccountId: data.id,
                ...data,
            })
            setRequesting(false)
            if (res.status) {
                toggleEdit()
                setbankListRefreshkey(e => e + 1)
                toast.success(res.message)
            } else toast.error(res.message)
        } catch (error) {
            setRequesting(false)
            toast.error('Something went wrong')
        }
    }

    const handlePrimaryConversion = async () => {
        try {
            setPrimaryRequest(true)
            const res = await bankService.makePrimary({
                bankAccountId: accountData.id,
            })
            if (res.status) {
                setbankListRefreshkey(e => e + 1)
                toast.success(res.message)
            } else toast.error(res.message)
            setPrimaryRequest(false)
        } catch (error) {
            toast.error('Something went wrong')
            setPrimaryRequest(false)
        }
    }

    return (
        <>
            <div className="tw-mb-4 tw-p-4 tw-rounded tw-ring-1 tw-flex tw-gap-y-2 tw-flex-col tw-items-start tw-justify-between md:tw-flex-row tw-text-gray-600 tw-ring-gray-500 ">
                <div>
                    <h4 className="tw-p-0 tw-mb-2">
                        {/* {accountData.account_holder_name} <span className="tw-text-xs tw-capitalize">({accountData.account_holder_type})</span> */}
                       <span className="tw-text-xs tw-capitalize">({accountData.bank_name})</span>
                        {accountData.default_for_currency && (
                            <span className="tw-capitalize tw-bg-blue-500 tw-w-max tw-text-white tw-text-xs tw-p-1 tw-rounded tw-mx-2  ">
                                Primary
                            </span>
                        )}
                    </h4>
                    <p className="tw-p-0 tw-mb-2 tw-text-sm ">Routing number: {accountData.routing_number}</p>
                    <p className="tw-p-0 tw-mb-2 tw-text-sm ">Account number: XXXXXXXXXXXX{accountData.last4}</p>
                    <div className="tw-flex tw-gap-x-2 tw-items-start">
                        {/* {accountStatusCard(accountData.status)} */}
                        {!accountData.default_for_currency && (
                            <span
                                onClick={handlePrimaryConversion}
                                className={`tw-w-max tw-text-sm tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-ring-1 tw-text-green-600 tw-ring-green-600 tw-boder-0 tw-border `}
                            >
                                {primaryRequest ? 'Please wait...' : ' Make Primary'}
                            </span>
                        )}
                    </div>
                    {/* <div className="tw-flex tw-gap-x-2 tw-items-start">
            {accountStatusCard(accountData.status)}
            {accountData.status === "verified" && !accountData.isPrimary && (
              <span
                onClick={handlePrimaryConversion}
                className={`tw-w-max tw-text-sm tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-ring-1 tw-text-green-600 tw-ring-green-600 tw-boder-0 tw-border `}
              >
                {primaryRequest ? "Please wait..." : " Make Primary"}
              </span>
            )}
          </div> */}
                </div>

                <div className="tw-flex tw-gap-2">
                    {!accountData.default_for_currency && (
                        <span
                            onClick={() => setAlert(true)}
                            className="tw-cursor-pointer  tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-grid tw-place-content-center tw-text-primary  "
                        >
                            <FaTrash size={14} />
                        </span>
                    )}
                    <span
                        onClick={toggleEdit}
                        className="tw-cursor-pointer  tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-grid tw-place-content-center tw-text-primary  "
                    >
                        <MdModeEdit size={20} />
                    </span>
                    {/* )} */}
                </div>
            </div>
            {alert && (
                <AlertBox
                    onProceed={() => {
                        handleDelete(accountData.id)
                        setAlert(false)
                    }}
                    onCancle={() => {
                        setAlert(false)
                    }}
                    message={'Are you sure want to delete this bank account ?'}
                />
            )}
            {edit && (
                <CustomModal>
                    <BankForm
                        bankDetails={{
                            bankAccountId: accountData.id,
                            accountHolderType: accountData.account_holder_type,
                            accountHolderName: accountData.account_holder_name,
                            routingNumber: accountData.routing_number,
                            currency: accountData.currency,
                            country: accountData.country,
                            accountNumber: `XXXXXXXXXXXX${accountData.last4}`,
                            confirmAccountNumber: `XXXXXXXXXXXX${accountData.last4}`,
                        }}
                        disabled={{
                            routingNumber: accountData.routing_number,
                            currency: accountData.currency,
                            country: accountData.country,
                            accountNumber: `XXXXXXXXXXXX${accountData.last4}`,
                            confirmAccountNumber: `XXXXXXXXXXXX${accountData.last4}`,
                        }}
                        title="Edit Bank Account"
                        onSubmit={editBankAccount}
                        onClose={toggleEdit}
                    />
                </CustomModal>
            )}
            {verify && (
                <CustomModal>
                    <PayoutVerificationModal bankAccountId={accountData.id} onClose={toggleVerify} setRefreshKey={setbankListRefreshkey} />
                </CustomModal>
            )}
        </>
    )
}

function BankForm({ onSubmit, title = 'Add bank account', onClose, bankDetails = {}, disabled = {} }) {
    const [accountForm, setAccountForm] = useState(bankDetails)
    const [bankAdding, SetBankAdding] = useState(false)

    const setValue = (tag, value) => {
        setAccountForm({ ...accountForm, [tag]: value })
    }

    const handleFormSubmit = e => {
        e.preventDefault()
        onSubmit(accountForm, SetBankAdding)
    }

    return (
        <div className="tw-bg-white md:tw-rounded-md md:tw-p-4">
            <form onSubmit={handleFormSubmit}>
                <div className="modal-body">
                    <h3 className="tw-text-xl tw-mb-4">{title}</h3>
                    <div className="row">
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Currency</label>
                                <select
                                    name="currency"
                                    disabled={disabled['currency']}
                                    className="form-control wide"
                                    onChange={e => setValue('currency', e.target.value)}
                                    value={accountForm['currency']}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="usd">USD</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Country</label>
                                <select
                                    name="country"
                                    className="form-control wide"
                                    onChange={e => setValue('country', e.target.value)}
                                    value={accountForm['country']}
                                    disabled={disabled['country']}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="US">US</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Account Holder Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Account Holder Name"
                                    required
                                    name="accountHolderType"
                                    onChange={e => setValue('accountHolderName', e.target.value)}
                                    disabled={disabled['accountHolderName']}
                                    value={accountForm['accountHolderName']}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Account Holder Type</label>
                                <select
                                    className="form-control wide"
                                    required
                                    name="accountHolderType"
                                    onChange={e => setValue('accountHolderType', e.target.value)}
                                    value={accountForm['accountHolderType']}
                                    disabled={disabled['accountHolderType']}
                                >
                                    <option value="">Select</option>
                                    <option value="individual">Individual</option>
                                    <option value="company">Company</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Routing Number</label>
                                <input
                                    type="text"
                                    name="routingNumber"
                                    className="form-control"
                                    placeholder="Routing Number"
                                    required
                                    onChange={e => setValue('routingNumber', e.target.value)}
                                    value={accountForm['routingNumber']}
                                    disabled={disabled['routingNumber']}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Account Number</label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                    className="form-control"
                                    placeholder="Account Number"
                                    required
                                    onChange={e => setValue('accountNumber', e.target.value)}
                                    value={accountForm['accountNumber']}
                                    disabled={disabled['accountNumber']}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                                <label className="text-label form-label">Confirm Account Number</label>
                                <input
                                    type="text"
                                    name="confirmAccountNumber"
                                    className="form-control"
                                    placeholder="Confirm Account Number"
                                    required
                                    onChange={e => setValue('confirmAccountNumber', e.target.value)}
                                    value={accountForm['confirmAccountNumber']}
                                    disabled={disabled['confirmAccountNumber']}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-gap-2">
                            <button onClick={onClose} type="button" className="btn btn-secondary mb-3  ">
                                Cancel
                            </button>
                            <button disabled={bankAdding} type="submit" className="btn btn-primary mb-3  ">
                                {bankAdding ? 'Please wait ...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

function PayoutMethod() {
    const [addedAccount, setAddedAccount] = useState([])
    const [bankListRefreshkey, setbankListRefreshkey] = useState(0)
    const [addPayout, setAddPayout] = useState(false)
    const [host, setDetail] = useState({})
    const [refreshKey, setRefreshKey] = useState(0)
    const [suspense, setSuspense] = useState(true)
    const [isStripeVerified, setIsStripeVerified] = useState(false)
    const [stripeAllAccountDetails, setStripeAllAccountDetails] = useState({})
    const [linkBtnStatus, setLinkBtnStatus] = useState(false)
    const [gettingLoginURL, setGettingLoginURL] = useState(false)
    const [currentStripeStatus, setCurrentStripeStatus] = useState(false)
    const [showUpdateVerificationDetailButton, setShowUpdateVerificationDetailButton] = useState(false)
    const history = useHistory()

    async function getStripeLinkUrl() {
        setLinkBtnStatus(e => !e)
        const res = await bankService.getStripeLinkUrl()

        if (res.data && res.data.url) {
            window.location.replace(res.data.url)
        }
        setLinkBtnStatus(e => !e)
    }

    function connect_account() {
        history.push('/settings/account_connect')
    }

    const fetchUserDetail = () => {
        userService.getDetail({}).then(objS => {
            if (objS.status) {
                setDetail(objS.data)
            }
        })
    }

    useEffect(() => {
        if (refreshKey !== 0) {
            fetchUserDetail()
        }
    }, [refreshKey])

    useEffect(() => {
        fetchUserDetail()
    }, [])

    useEffect(async () => {
        await bankService.getStripeDetail().then(async res => {
            if (res.data.business_type !== null) {
                const accountTypeIndividual = res.data.business_type === 'individual' ? true : false
                const verficationStatus = accountTypeIndividual ? res.data.individual.verification.status : res.data.company.verification.status
                setCurrentStripeStatus(verficationStatus)
            }
            const status = res.data.charges_enabled && res.data.payouts_enabled
            setIsStripeVerified(status)
            setStripeAllAccountDetails(res.data)

            const stripeDetails = res.data
            if (stripeDetails) {
                if (stripeDetails.requirements) {
                    const currently_due = stripeDetails.requirements.currently_due.filter(item => item !== 'external_account')

                    if (currently_due.length > 0 && stripeDetails.business_type) {
                        setShowUpdateVerificationDetailButton(true)
                    }
                }
            }

            setSuspense(false)
        })
    }, [])

    useEffect(() => {
        const fetchAccountList = async () => {
            try {
                const res = await bankService.bankList()
                if (res.status) {
                    setAddedAccount(res.data.data)
                } else {
                    toast.error(res.message)
                    setAddedAccount([])
                }
            } catch (error) {
                toast.error('Something went wrong')
            }
        }

        fetchAccountList()
    }, [bankListRefreshkey])

    // update the status payoutVerification status to users details is strip status is verfied
    useEffect(async () => {
        if (isStripeVerified && host.payoutVerification !== 1) {
            // await userService.UpdateProfile({
            //   ...host,
            //   // payoutVerification: 1,
            //   //stripeInReview: 0,
            // });
        }
    }, [host, isStripeVerified])

    const toggleAddPayout = () => {
        setAddPayout(e => !e)
    }

    const saveBankAccount = (accountForm, setRequesting) => {
        accountForm.accountHolderName = accountForm.accountHolderName.trim()
        accountForm.accountNumber = accountForm.accountNumber.trim()
        accountForm.confirmAccountNumber = accountForm.confirmAccountNumber.trim()
        accountForm.routingNumber = accountForm.routingNumber.trim()
        if (accountForm.accountNumber != accountForm.confirmAccountNumber) {
            toast.info('Account number and confirm account number must be same.')
            return
        }
        setRequesting(true)
        var postObj = { hostId: host.id, ...accountForm }

        bankService.addBank(postObj).then(
            objS => {
                if (objS.status) {
                    setbankListRefreshkey(e => e + 1)
                    host.payoutVerification = true
                    saveData(host)
                    setAddPayout(false)
                    toast.success('Bank account added successfully')
                    setRefreshKey(oldKey => oldKey + 1)
                } else {
                    toast.error(objS.message)
                }
                setRefreshKey(e => e + 1)
                setRequesting(false)
            },
            error => {
                setRequesting(false)
            }
        )
    }

    const handleDelete = async id => {
        try {
            const res = await bankService.deleteBank({ bankAccountId: id })

            if (res.status) {
                toast.success(res.message)
                setAddedAccount(el => el.filter(curr => curr.id !== id))
                setbankListRefreshkey(oldKey => oldKey + 1)
            } else toast.error(res.message)
        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    async function handleStripeDashboardNavigation() {
        try {
            setGettingLoginURL(e => !e)
            const res = await bankService.getStripeLoginLinkUrl()
            if (res.status) {
                // window.location.replace(res.data.url);
                window.open(res.data.url, '_blank')
            }
        } catch (error) {
            toast.error('something bad happend')
        } finally {
            setGettingLoginURL(e => !e)
        }
    }

    const accountMessages = () => {
        if (
            stripeAllAccountDetails.requirements &&
            stripeAllAccountDetails.requirements.errors &&
            stripeAllAccountDetails.requirements.errors.length > 0
        ) {
            for (let error of stripeAllAccountDetails.requirements.errors) {
                return `An error occurred. ${error.reason}`
            }
            return 'An error occurred. Please update your verification information and try once more.'
        }

        if (
            stripeAllAccountDetails.business_type === null &&
            stripeAllAccountDetails.charges_enabled === false &&
            stripeAllAccountDetails.payouts_enabled === false
        ) {
            return 'Your account is not active. Please connect your payout method below.'
        } else {
            if (
                stripeAllAccountDetails.business_type &&
                stripeAllAccountDetails.charges_enabled === false &&
                stripeAllAccountDetails.requirements.currently_due.filter(item => item !== 'external_account').length > 0
            ) {
                return "Your account has missing information. Please fill form from 'Link Stripe account' button below."
            } else if (stripeAllAccountDetails.business_type !== null && stripeAllAccountDetails.charges_enabled === false) {
                return (
                    <div>
                        Your account is under review. Please refresh this screen periodically to check for updates. If your status is not activated
                        within 1 hour, contact support at{' '}
                        <span className="tw-cursor-pointer tw-underline" onClick={() => (window.location = 'mailto:support@crewmatesapp.com')}>
                            support@crewmatesapp.com
                        </span>
                        .
                    </div>
                )
            } else if (
                stripeAllAccountDetails.business_type !== null &&
                stripeAllAccountDetails.charges_enabled === true &&
                stripeAllAccountDetails.payouts_enabled === false &&
                addedAccount.length === 0
            ) {
                return 'Please add bank account details to start receiving payouts.'
            } else if (addedAccount.length > 0) {
                return (
                    <div>
                        Your account is under review. Please refresh this screen periodically to check for updates. If your status is not activated
                        within 1 hour, contact support at{' '}
                        <span className="tw-cursor-pointer tw-underline" onClick={() => (window.location = 'mailto:support@crewmatesapp.com')}>
                            support@crewmatesapp.com
                        </span>
                        .
                    </div>
                )
            } else {
                return 'An error occurred. Please update your verification information and try once more.'
            }
        }
    }

    return (
        <>
            {suspense ? (
                <div className="tw-my-10 tw-flex">
                    <p className="tw-text-center tw-py-4">Loading...</p>
                </div>
            ) : (
                <div>
                    <div className="tw-flex">
                        <div className="tw-text-sm tw-font-medium">
                            <p className="tw-mb-2 tw-text-lg">Account Status</p>
                            <div className="tw-mb-2">
                                {isStripeVerified ? (
                                    <div className="tw-rounded-md tw-p-2 tw-px-4 tw-text-white tw-font-semibold tw-bg-green-500 tw-block tw-text-sm">
                                        Payout method is active.
                                    </div>
                                ) : (
                                    <span className="tw-rounded-md tw-p-2 tw-text-white tw-bg-red-500 tw-font-semibold tw-inline-block tw-text-sm">
                                        {accountMessages()}
                                    </span>
                                )}
                            </div>
                            <div>
                                {stripeAllAccountDetails.business_type === null &&
                                    stripeAllAccountDetails.charges_enabled === false &&
                                    stripeAllAccountDetails.payouts_enabled === false && (
                                        <button
                                            disabled={currentStripeStatus === 'pending'}
                                            onClick={connect_account}
                                            className="btn btn-primary btn-sm tw-w-max"
                                        >
                                            {currentStripeStatus === 'pending' ? 'Under Review...' : 'Add Verification Details'}
                                        </button>
                                    )}
                                {showUpdateVerificationDetailButton && (
                                    <button onClick={connect_account} className="btn btn-primary btn-sm tw-w-max">
                                        {'Update Verification Details'}
                                    </button>
                                )}
                            </div>
                            <div>
                                {stripeAllAccountDetails.business_type !== null &&
                                    stripeAllAccountDetails.charges_enabled === true &&
                                    stripeAllAccountDetails.payouts_enabled === false &&
                                    addedAccount.length === 0 && (
                                        <>
                                            <p className="tw-text-sm tw-mb-2 mt-4">
                                                {/* Payout method is required before listing goes live. */}
                                                Payout method is required before accepting bookings. Please submit your listing in the properties tab
                                                once active.
                                            </p>
                                            <button onClick={() => setAddPayout(!addPayout)} className="btn btn-primary btn-sm tw-w-max">
                                                {/* Add bank account */}
                                                Add Bank Account
                                            </button>
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="tw-max-w-md">
                        {stripeAllAccountDetails.business_type !== null &&
                            stripeAllAccountDetails.charges_enabled === true &&
                            stripeAllAccountDetails.payouts_enabled === true &&
                            addedAccount.length > 0 && (
                                <>
                                    <button onClick={() => setAddPayout(!addPayout)} className="btn btn-primary btn-sm tw-w-max tw-mb-4 mt-4">
                                        {/* Add bank account */}
                                        Add Bank Account
                                    </button>
                                    {addedAccount.map(account => (
                                        <BankAccountCard
                                            isLast={addedAccount.length === 1}
                                            setbankListRefreshkey={setbankListRefreshkey}
                                            handleDelete={handleDelete}
                                            accountData={account}
                                            key={account.id}
                                        />
                                    ))}
                                </>
                            )}
                    </div>
                    {addPayout && (
                        <CustomModal>
                            <BankForm onSubmit={saveBankAccount} onClose={toggleAddPayout} />
                        </CustomModal>
                    )}
                </div>
            )}
        </>
    )
}

export default PayoutMethod
