import React, { useState } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { BsPlusCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { bankService } from "../../../_services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
var Modal = require("react-bootstrap-modal");

export const SecurityCheckPoints = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  requesting,
  save,
  updating,
  hostDetail,
  isPaymentConfirm
}) => {
  const [stepForm, setStepForm] = useState({
    hostId: formData.hostId,
  });
  
  const [accountForm, setAccountForm] = useState({});
  const [bankAdding, SetBankAdding] = useState(false);

  const handleSave = async () => {
    
    save(stepForm);
    
  };
  const history = useHistory();

  const setValue = (tag, value) => {
    setAccountForm({ ...accountForm, [tag]: value });
  };

  const saveBankAccount = (e) => {
    e.preventDefault();
    if (accountForm.accountNumber != accountForm.confirmAccountNumber) {
      toast.info("Account number and confirm account number must be same.");
      return;
    }

    SetBankAdding(true);
    var postObj = { hostId: hostDetail.id, ...accountForm };
    bankService.addBank(postObj).then(
      (objS) => {
        if (objS.status) {
          window.$("#payoutmethod").modal("hide");
          window.$(".modal-backdrop").remove();
          toast.success("Bank account added successfully");
          setAccountForm({});
        } else {
          toast.error(objS.message);
        }
        SetBankAdding(false);
      },
      (error) => {
        window.$("#payoutmethod").modal("hide");
        window.$(".modal-backdrop").remove();
        SetBankAdding(false);
      }
    );
  };
  const goOverSerringPage = () => {
    // setBankAccountModal((e) => !e);

    // saveAsDraft();
    history.push("/setting/payout-method");
  };
  console.log("hostDetailPayout - security " +localStorage.getItem("hostDetailPayout")  );
  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
          {
              hostDetail.payoutVerification == 1 
              ?  10 : 11 }
          </span>{" "}
          Security Checkpoint
        </h4>
        <div className="row">
          {hostDetail ? (
            <div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  1. Email
                  {hostDetail.personalEmailVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.personalEmailVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button className="btn btn-secondary">Verify Email</button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  2. Phone
                  {hostDetail.phoneVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.phoneVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button className="btn btn-secondary">Verify Phone</button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  3. Selfie & ID
                  {hostDetail.idVerificationStatus == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.idVerificationStatus == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#payoutmethod"
                >
                  Connect Now
                </button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  4. Payout Information
                  {hostDetail.payoutVerification  == 1 || isPaymentConfirm == true
                    ? " Added "
                    : " Not Added "}
                  {hostDetail.payoutVerification == 1 || isPaymentConfirm == true ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {!hostDetail.payoutVerification == 1 &&  isPaymentConfirm == false && (
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    // onClick={toggleBankAccountModal}
                    onClick={goOverSerringPage}
                  >
                    Not Added
                  </button>
                )}
              </div>
              {/* <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  4. Payout
                  {hostDetail.payoutVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.payoutVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {hostDetail.payoutVerification != 1 && (
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#payoutmethod"
                  >
                    Connect Now
                  </button>
                )}
              </div> */}
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="modal  bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          id="payoutmethod"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Your Bank Account</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <form onSubmit={saveBankAccount}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Currency
                        </label>
                        <select
                          className="form-control wide"
                          onChange={(e) => setValue("currency", e.target.value)}
                          value={accountForm["currency"]}
                          required
                        >
                          <option value="">Select</option>
                          <option value="USD">USD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">Country</label>
                        <select
                          name="country"
                          className="form-control wide"
                          onChange={(e) => setValue("country", e.target.value)}
                          value={accountForm["country"]}
                          required
                        >
                          <option value="">Select</option>
                          <option value="US">USA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Holder Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Holder Name"
                          required
                          name="accountHolderType"
                          onChange={(e) =>
                            setValue("accountHolderName", e.target.value)
                          }
                          value={accountForm["accountHolderName"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Holder Type
                        </label>
                        <select
                          className="form-control wide"
                          required
                          name="accountHolderType"
                          onChange={(e) =>
                            setValue("accountHolderType", e.target.value)
                          }
                          value={accountForm["accountHolderType"]}
                        >
                          <option value="">Select</option>
                          <option value="individual">Individual</option>
                          <option value="company">Company</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Routing Number
                        </label>
                        <input
                          type="text"
                          name="routingNumber"
                          className="form-control"
                          placeholder="Routing Number"
                          required
                          onChange={(e) =>
                            setValue("routingNumber", e.target.value)
                          }
                          value={accountForm["routingNumber"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Number
                        </label>
                        <input
                          type="text"
                          name="accountNumber"
                          className="form-control"
                          placeholder="Account Number"
                          required
                          onChange={(e) =>
                            setValue("accountNumber", e.target.value)
                          }
                          value={accountForm["accountNumber"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Confirm Account Number
                        </label>
                        <input
                          type="text"
                          name="confirmAccountNumber"
                          className="form-control"
                          placeholder="Confirm Account Number"
                          required
                          onChange={(e) =>
                            setValue("confirmAccountNumber", e.target.value)
                          }
                          value={accountForm["confirmAccountNumber"]}
                        />
                      </div>
                    </div>
                    <button
                      disabled={bankAdding}
                      type="submit"
                      className="btn btn-secondary mb-3"
                    >
                      {bankAdding ? "Please wait ..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>

        {hostDetail && hostDetail.payoutVerification == 1 && (
          <button
            disabled={updating}
            onClick={handleSave}
            className="btn btn-secondary tw-mb-2"
          >
            {updating ? "Updating ..." : "Review & Publish"}
          </button>
        )}
      </div>
    </div>
  );
};
