import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Navbar from "../../Navbar";
import TopBar from "../../topbar";
import { BsPlusLg, BsTrash, BsPencilSquare, BsCheck2All } from "react-icons/bs";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";
import { getData } from "../../../_helpers";

import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { getAddressByLatLng } from "../../../_helpers/GeoCoder";
import {
  AiOutlineInfoCircle,
  AiFillDelete,
  AiOutlinePlus,
  AiOutlineMinus,
} from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { BsPlusCircleFill } from "react-icons/bs";
import { nanoid } from "nanoid";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../../../layout/footer";
import {
  amenityService,
  safetyFeatureService,
  propertyService,
  userService,
  bankService,
} from "../../../_services";
import constant from "../../../_config/constant";
import { processMediaUrl } from "../../../_helpers/utils";
import { SleepingArrangements } from "./SleepingArrangements";
import { Wrapper } from "./Wrapper";
import { BasicDetailsForm } from "./BasicDetailsForm";
import { Details } from "./Details";
import { HouseRules } from "./HouseRules";
import { AddPhotos } from "./AddPhotos";
import { Amenities } from "./Amenities";
import { SafetyFeatures } from "./SafetyFeatures";
import { PricingAndFee } from "./PricingAndFee";
import { Terms } from "./Terms";
import { SecurityCheckPoints } from "./SecurityCheckPoints";
import { PayoutMethod } from "./PayoutMethod";
var Modal = require("react-bootstrap-modal");

function EditProperty(props) {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const [formStep, setFormStep] = useState(0);
  
  const [formData, setFormData] = useState({});
  const [amenityList, setAmenityList] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [hostDetail, setDetail] = useState({});
  const [isPaymentConfirm, setIspaymentConfirm] = useState(hostDetail.payoutVerification);
  const [isButtonDisable, setIsButtonDisable] = useState(hostDetail.payoutVerification);

  const [safetyFeatureList, setSafetyFeatureList] = useState([]);
  const [requesting, setRequest] = useState(false);
  const [updating, setUpdating] = useState(false);
  const params = useParams();
  
  const handleNextStep = (step) => {
    // if (step < 10) {
    //   setFormStep((step) => step + 1);
    // }
    if(hostDetail.payoutVerification == 1 )
    {
      console.log("step--11---", step);
      if (step < 9) {
        setFormStep((step) => step + 1);
      }
    }
    else
    {
      console.log("step--0---", step);
      if (step < 10) {
        setFormStep((step) => step + 1);
      }
    }
  };

  const handlePrevStep = (step) => {
    if (step > 0) {
      setFormStep((step) => step - 1);
    }
  };

  const save = (data) => {
    //alert(formStep);
    if (formStep == 10) {
      props.history.push("/properties");
    }
    setUpdating(true);
    var reqData = Object.assign({}, data);
    reqData["propertyId"] = params.id;
    reqData["step"] = formStep;
    if (reqData.step == 0) {
      reqData.location = Object.values(data.location);
    }
    propertyService._update(reqData).then(
      (objS) => {
        if (objS.status) {
          toast.success("Details updated.");
        } else {
          toast.error("Something went wrong.");
        }
        setUpdating(false);
      },
      (error) => {
        setUpdating(false);
      }
    );
  };

  const savePhoto = (data) => {
    setUpdating(true);
    data["propertyId"] = params.id;
    propertyService.updatePhoto(data).then(
      (objS) => {
        if (objS.status) {
          toast.success("Details updated.");
        } else {
          toast.error("Something went wrong.");
        }
        setUpdating(false);
      },
      (error) => {
        setUpdating(false);
      }
    );
  };

  const handleFormSubmission = () => {
    alert("jk");
    setRequest(true);
    formData.propertyId = params.id;
    propertyService.update(formData).then(
      (objS) => {
        props.history.push("/properties");
      },
      (error) => {}
    );
  };
  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      event.preventDefault();
      document.body.classList.remove("modal-open");
      window.$("#addRoomModal").modal("hide");
      window.$(".modal-backdrop").remove();
    });
  }, []);

  useEffect(() => {
    const prefetch = () => {
      amenityService.List({}).then(
        (objS) => {
          if (objS.status) setAmenityList(objS.data);
        },
        (error) => {}
      );
      safetyFeatureService.List({}).then(
        (objS) => {
          if (objS.status) setSafetyFeatureList(objS.data);
        },
        (error) => {}
      );

      userService.getDetail({}).then(
        (objS) => {
          if (objS.status) {
            setDetail(objS.data);
          }
        },
        (error) => {}
      );

      propertyService.getDetail(params.id).then(
        (objS) => {
          if (objS.status) {
            objS.data.location =
              objS.data.location &&
              objS.data.location[0] &&
              objS.data.location[1]
                ? { lat: objS.data.location[1], lng: objS.data.location[0] }
                : { lat: constant.lat, lng: constant.lng };
            setFormData({ ...objS.data });
          }
          setApiCalled(true);
        },
        (error) => {
          setApiCalled(true);
        }
      );
    };

    prefetch();
  }, []);
  console.log("hostDetailPayout - index " +hostDetail.payoutVerification  );
  localStorage.setItem("hostDetailPayout", hostDetail.payoutVerification);
  const handleFormStep = (i) => {
    setFormStep(i);
  };

  return (
    <Wrapper>
      <ToastContainer />
      {apiCalled && (
        <div className="row">
          {/* <form onSubmit={handleFormSubmission} enctype="multipart/form-data"> */}
          {formData.status != "Pending" ? (
            <div className="tw-py-4 tw-flex tw-flex-nowrap tw-overflow-x-auto">
              <span
                onClick={() => handleFormStep(0)}
                className={`tw-flex-shrink-0 tw-rounded-l-md tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 0
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Basics
              </span>
              <span
                onClick={() => handleFormStep(1)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 1
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Details
              </span>
              <span
                onClick={() => handleFormStep(2)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 2
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                House Rules
              </span>
              <span
                onClick={() => handleFormStep(3)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 3
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Photos
              </span>
              <span
                onClick={() => handleFormStep(4)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 4
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Amenities
              </span>
              <span
                onClick={() => handleFormStep(5)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 5
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Safety Features
              </span>
              <span
                onClick={() => handleFormStep(6)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 6
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Sleeping Arrangements
              </span>
              <span
                onClick={() => handleFormStep(7)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 7
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Pricing and Fees
              </span>
              <span
                onClick={() => handleFormStep(8)}
                className={`tw-p-4 tw-text-sm tw-border-b-2 tw-flex-shrink-0  tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 8
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Terms
              </span>
              {hostDetail.payoutVerification == 1 
              ?
              <span
                onClick={() => handleFormStep(9)}
                className={`tw-flex-shrink-0  tw-rounded-r-md  tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 9
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Security Checkpoint
              </span>
              :
                <>
                <span
                onClick={() => handleFormStep(9)}
                className={`tw-flex-shrink-0  tw-rounded-r-md  tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 9
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Payout Method
              </span>
              <span
                    onClick={() => handleFormStep(10)}
                    className={`tw-flex-shrink-0  tw-rounded-r-md  tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                      formStep === 10
                        ? "tw-bg-[#23426e] tw-text-white "
                        : "tw-bg-gray-100"
                    }`}
                  >
                    Security Checkpoint
                  </span>
           </>
              
              }
              {/* <span
                onClick={() => handleFormStep(9)}
                className={`tw-flex-shrink-0  tw-rounded-r-md  tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                  formStep === 9
                    ? "tw-bg-[#23426e] tw-text-white "
                    : "tw-bg-gray-100"
                }`}
              >
                Payout Method
              </span> */}
              
            </div>
          ) : (
            <div></div>
          )}

          <BasicDetailsForm
            className={`${formStep === 0 ? "tw-block" : "tw-hidden"}`}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            save={save}
            updating={updating}
          />
          <Details
            className={`${formStep === 1 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <HouseRules
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 2 ? "tw-block" : "tw-hidden"}`}
            save={save}
            updating={updating}
          />
          <AddPhotos
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 3 ? "tw-block" : "tw-hidden"}`}
            savePhoto={savePhoto}
            updating={updating}
          />

          <Amenities
            className={`${formStep === 4 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            amenityList={amenityList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <SafetyFeatures
            className={`${formStep === 5 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            safetyFeatureList={safetyFeatureList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <SleepingArrangements
            formData={formData}
            className={`${formStep === 6 ? "tw-block" : "tw-hidden"}`}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <PricingAndFee
            className={`${formStep === 7 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <Terms
            className={`${formStep === 8 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />

          {
              hostDetail.payoutVerification == 1 
              ? 
              <SecurityCheckPoints
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
            
            hostDetail={hostDetail}
            isPaymentConfirm={isPaymentConfirm}
            />
              : 
  
              <PayoutMethod
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            //save={save}
            updating={updating}
            hostDetail={hostDetail}
            setIspaymentConfirm={setIspaymentConfirm}
            isPaymentConfirm={isPaymentConfirm}
            setIsButtonDisable={setIsButtonDisable}
            isButtonDisable={isButtonDisable}
          />
  
          }
          {
            hostDetail.payoutVerification == 0
            ? 
            <SecurityCheckPoints
            className={`${formStep === 10 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
            hostDetail={hostDetail}
            isPaymentConfirm={isPaymentConfirm}
          />
            :
            <></>

          }
  
          {/* <PayoutMethod
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            //save={save}
            updating={updating}
            hostDetail={hostDetail}
          />
          <SecurityCheckPoints
            className={`${formStep === 10 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
            hostDetail={hostDetail}
          /> */}
        </div>
      )}
    </Wrapper>
  );
}

export default EditProperty;
