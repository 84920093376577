import React from 'react'
import { processMediaUrl } from '../../_helpers/utils'

function ChatCard({ onSelectChannel, data, activeChannel, setViewList, updateUnreadCount, cssChangeClass }) {
    // Ensure data is defined before proceeding
    if (!data) {
        return null
    }

    const handleClick = () => {
        // Ensure channelId is defined before proceeding
        if (data.channelId) {
            updateUnreadCount(data.channelId, 0) // Reset unread count
            onSelectChannel({
                name: data.name,
                channelId: data.channelId,
                property: data.property,
                lastMessageDate: data.lastMessageDate,
                unreadMessages: data.unreadMessages,
                lastMessageText: data.lastMessageText,
                users: data.users,
            })
            setViewList(true)
        }
    }

    const userStatusForChannel = (data.users && data.users.displayState) || false

    return (
        <div
            onClick={() => {
                handleClick();
                cssChangeClass();
            }}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#23426e',
                padding: '1rem',
                backgroundColor: activeChannel && activeChannel.channelId === data.channelId ? '#e6ebf5' : '',
                borderRadius: '0.25rem',
                transition: 'background-color 0.3s',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: '2.75rem',
                    }}
                >
                    <img
                        alt=""
                        src={processMediaUrl(data.property.picture)}
                        style={{
                            height: '2.75rem',
                            width: '2.75rem',
                            borderRadius: '9999px',
                            objectFit: 'cover',
                        }}
                    />
                    {/* {userStatusForChannel ? (
                        <span
                            style={{
                                bottom: '3px',
                                right: '3px',
                                height: '0.5rem',
                                width: '0.5rem',
                                display: 'block',
                                backgroundColor: 'rgb(6, 214, 160)',
                                borderRadius: '9999px',
                                border: '1px solid white',
                                position: 'absolute',
                            }}
                        ></span>
                    ) : (
                        <span
                            style={{
                                bottom: '3px',
                                right: '3px',
                                height: '0.5rem',
                                width: '0.5rem',
                                display: 'block',
                                backgroundColor: 'rgb(255, 0, 0)',
                                borderRadius: '9999px',
                                border: '1px solid white',
                                position: 'absolute',
                            }}
                        ></span>
                    )} */}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className='minimal-name'
                            style={{
                                fontSize: '0.875rem',
                                fontWeight: '500',
                                marginBottom: '0.25rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {data.property.name}
                        </p>
                        {data.lastMessageText && (
                            <p
                                style={{
                                    fontSize: '0.75rem',
                                    color: '#6b7280',
                                }}
                            >
                                {new Date(data.lastMessageDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                            </p>
                        )}
                    </div>
                    <p
                        style={{
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            marginBottom: '0.25rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {data.property.user}
                    </p>
                    {data.property.reservationId && (
                        <p
                            style={{
                                fontSize: '0.75rem',
                                fontWeight: '500',
                                marginBottom: '0.25rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {data.property.reservationId}
                        </p>
                    )}
                    {data.lastMessageText && (
                        <p
                            style={{
                                fontSize: '0.75rem',
                                color: '#6b7280',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '250px',
                            }}
                        >
                            {data.lastMessageText}
                        </p>
                    )}
                    {Boolean(data.unreadMessages) && (
                        <div
                            style={{
                                height: '1.5rem',
                                width: '1.5rem',
                                borderRadius: '9999px',
                                backgroundColor: '#c6d9f5',
                                fontSize: '0.75rem',
                                display: 'grid',
                                placeContent: 'center',
                                color: '#23426e',
                                fontWeight: '600',
                                position: 'absolute',
                                right: '0',
                                top: '1rem',
                                marginTop: '5px',
                            }}
                        >
                            {data.unreadMessages}
                        </div>
                    )}
                    {data.invited && (
                        <p className="tw-text-xs">
                            {
                                <p
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: '#23426e',
                                    }}
                                >
                                    New invited
                                </p>
                            }
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChatCard
