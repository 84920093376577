import React, { useEffect, useState, useRef } from "react";
import CustomModal from "../../ReactModal";
import Persona from "persona";

import { AiOutlinePlus } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { BsPlusCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userService, bankService } from "../../../_services";
import constant from "../../../_config/constant";
import { OTPInput } from "./OTPInput";
import { AddBankAccountForm } from "./AddBankAccountForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppConfig } from "../../../Config";
var Modal = require("react-bootstrap-modal");

export const SecurityCheckPoints = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  requesting,
  hostDetail,
  setDetail,
  saveAsDraft,
  draftRequesting,
  isPaymentConfirm
}) => {
  const [addedBankAccount, setAddedBankAccount] = useState([]);
  const [bankListRefreshkey, setbankListRefreshkey] = useState(0);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [payoutModal, setPayoutModal] = useState(false);
  const [selfieModal, setSelfieModal] = useState(false);
  const [verifyPersonalEmailModal, setVerifyPersonalEmailModal] = useState(
    false
  );
  const [verifyPhoneModal, setVerifyPhoneModal] = useState(false);
  const [verifyPayoutMethod, setVerifyPayoutMethod] = useState(false);

  const [phoneVerificationRequest, setphoneVerificationRequest] = useState(
    false
  );
  const [emailVerificationRequest, setEmailVerificationRequest] = useState(
    false
  );
  const userId = JSON.parse(localStorage.getItem(constant.DATA_KEY)).id;

  const phoneOtpRef = useRef();
  const emailOtpRef = useRef();

  const [accountForm, setAccountForm] = useState({});
  const [bankAdding, SetBankAdding] = useState(false);
  const history = useHistory();

  const setValue = (tag, value) => {
    setAccountForm({ ...accountForm, [tag]: value });
  };

  const togglePayout = () => {
    setPayoutModal(!payoutModal);
  };

  const toggleSelfieModal = () => {
    setSelfieModal(!selfieModal);
  };

  const togglePhoneModal = () => {
    setVerifyPhoneModal(!verifyPhoneModal);
  };

  const togglePersonalEmailModal = () => {
    setVerifyPersonalEmailModal(!verifyPersonalEmailModal);
  };

  const toggleVerifyPayoutMethod = () => {
    setVerifyPayoutMethod((e) => !e);
  };

  const toggleBankAccountModal = () => {
    // setBankAccountModal((e) => !e);

    saveAsDraft();
    history.push("/settings/account_connect");
  };

  const goOverSerringPage = () => {
    // setBankAccountModal((e) => !e);

    saveAsDraft();
    history.push("/setting/payout-method");
  };

  const resendPhoneOtp = async () => {
    try {
      const res = await userService.sendVerifyPhoneOtp();
      if (res.status) {
        toast.success("OTP sent successfully!");
      } else toast.error(res.message);
    } catch {
      toast.error("Something went wrong!");
    }
  };

  const resendEmailOtp = async () => {
    try {
      const res = await userService.sendVerifyEmailOtp();
      if (res.status) {
        toast.success("OTP sent successfully!");
      } else toast.error(res.message);
    } catch {
      toast.error("Something went wrong!");
    }
  };

  const verifyPhoneOtp = async (otp) => {
    try {
      setphoneVerificationRequest(true);
      const res = await userService.verifyPhone({ otp });

      setphoneVerificationRequest(false);
      if (res.status) {
        hostDetail.phoneVerification = 1;
        setDetail({ ...hostDetail });
        setVerifyPhoneModal(false);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setphoneVerificationRequest(false);
    }
  };

  const verifyEmailOtp = async (otp) => {
    try {
      setEmailVerificationRequest(true);
      const res = await userService.verifyEmail({ otp });

      setEmailVerificationRequest(false);
      if (res.status) {
        hostDetail.personalEmailVerification = 1;
        setDetail({ ...hostDetail });
        setVerifyPersonalEmailModal(false);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setEmailVerificationRequest(false);
    }
  };

  const updateIdVerificationStatus = () => {
    userService.updateIdVerificationStatus().then((objS) => {
      if (objS.status) {
        toast.success(objS.message);
        hostDetail.idVerificationStatus = 1;
        setDetail({ ...hostDetail });
      } else {
        toast.error(objS.message);
      }
    });
  };

  const saveBankAccount = (accountForm) => {
    if (accountForm.accountNumber != accountForm.confirmAccountNumber) {
      toast.info("Account number and confirm account number must be same.");
      return;
    }
    SetBankAdding(true);
    var postObj = { hostId: hostDetail.id, ...accountForm };
    bankService.addBank(postObj).then(
      (objS) => {
        if (objS.status) {
          toast.success("Bank account added successfully");
          hostDetail.payoutVerification = true;
          setAccountForm({});
        } else {
          toast.error(objS.message);
        }
        toggleBankAccountModal();
        SetBankAdding(false);
        setbankListRefreshkey((e) => e + 1);
      },
      (error) => {
        toggleBankAccountModal();
        SetBankAdding(false);
      }
    );
  };

  useEffect(() => {
    const fetchAccountList = async () => {
      try {
        const res = await bankService.bankList();
        if (res.status) {
          setAddedBankAccount(res.data.data);
        } else {
          toast.error(res.message);
          setAddedBankAccount([]);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    fetchAccountList();
  }, [bankListRefreshkey]);

  return (
    <div className={className}>
      <div className=" ">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
          {
              hostDetail.payoutVerification == 1 
              ?  10 : 11 }
          </span>{" "}
          Security Checkpoint
        </h4>
        <div className="row">
          {hostDetail ? (
            <div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  1. Email
                  {hostDetail.personalEmailVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.personalEmailVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {hostDetail.personalEmailVerification != 1 && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      resendEmailOtp();
                      togglePersonalEmailModal();
                    }}
                  >
                    Verify
                  </button>
                )}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  2. Phone
                  {hostDetail.phoneVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.phoneVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {hostDetail.phoneVerification != 1 && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      resendPhoneOtp();
                      togglePhoneModal();
                    }}
                  >
                    Verify
                  </button>
                )}
              </div>
              {
                AppConfig.ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT && <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  3. Selfie & ID
                  {hostDetail.idVerificationStatus == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.idVerificationStatus == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {hostDetail.idVerificationStatus != 1 && (
                  <button
                    className="btn btn-secondary"
                    onClick={toggleSelfieModal}
                  >
                    Selfie & Id Verification
                  </button>
                )}
              </div>
              }
              
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
               {AppConfig.ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT ? '4.':'3.'} Payout Information
                  {hostDetail.payoutVerification == 1 || isPaymentConfirm == true
                    ? " Added "
                    : " Not Added "}
                  {hostDetail.payoutVerification == 1 || isPaymentConfirm == true ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {!hostDetail.payoutVerification == 1  && isPaymentConfirm == false && (
                  <button
                    className="btn btn-secondary mt-3"
                    data-bs-toggle="modal"
                    // onClick={toggleBankAccountModal}
                    onClick={goOverSerringPage}
                  >
                    Not Added
                  </button>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {verifyPersonalEmailModal && (
          <CustomModal>
            <div className="tw-w-[85%] tw-mx-auto ">
              <span
                onClick={togglePersonalEmailModal}
                className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 tw-block tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
              >
                <AiOutlinePlus
                  size={22}
                  className="tw-transform tw-rotate-45"
                />
              </span>
            </div>
            <div className="tw-bg-white tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
              <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                Enter OTP
              </h3>
              <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                Enter the OTP received on your Email
              </p>
              <OTPInput ref={emailOtpRef} />
              <div className="tw-text-sm tw-text-center">
                <button
                  onClick={() => {
                    verifyEmailOtp(emailOtpRef.current.value);
                  }}
                  disabled={emailVerificationRequest}
                  className="tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
                >
                  {emailVerificationRequest ? "Verfing.." : "Verify"}
                </button>
              </div>
              <p
                onClick={resendEmailOtp}
                className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
              >
                Resend OTP
              </p>
            </div>
          </CustomModal>
        )}

        {verifyPhoneModal && (
          <CustomModal>
            <div className="tw-w-[85%] tw-mx-auto ">
              <span
                onClick={togglePhoneModal}
                className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 tw-block tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
              >
                <AiOutlinePlus
                  size={22}
                  className="tw-transform tw-rotate-45"
                />
              </span>
            </div>
            <div className="tw-bg-white tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
              <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                Enter OTP
              </h3>
              <p className="tw-text-gray-700 tw-text-center tw-text-sm">
                Enter the OTP received on your mobile number
              </p>
              <OTPInput ref={phoneOtpRef} />
              <div className="tw-text-sm tw-text-center">
                <button
                  onClick={() => {
                    verifyPhoneOtp(phoneOtpRef.current.value);
                  }}
                  disabled={phoneVerificationRequest}
                  className="tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm tw-mx-auto tw-p-2 tw-px-4 tw-rounded tw-bg-primary tw-border-0 tw-text-white "
                >
                  {phoneVerificationRequest ? "Verfing.." : "Verify"}
                </button>
              </div>
              <p
                onClick={resendPhoneOtp}
                className="tw-text-sm tw-text-center tw-my-2 tw-text-primary tw-cursor-pointer"
              >
                Resend OTP
              </p>
            </div>
          </CustomModal>
        )}

        {selfieModal && (
          <CustomModal>
            <div className="tw-w-[85%] tw-mx-auto ">
              <span
                onClick={toggleSelfieModal}
                className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
              >
                <AiOutlinePlus
                  size={22}
                  className="tw-transform tw-rotate-45"
                />
              </span>
            </div>
            <div className="tw-bg-white tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
              <Persona.Inquiry
                referenceId={userId}
                templateId={constant.persona_template_id}
                environment={constant.persona_mode}
                onLoad={() => {}}
                onComplete={({ inquiryId, status, fields }) => {
                  // Inquiry completed. Optionally tell your server about it.

                  if (status === "completed") {
                    updateIdVerificationStatus();
                    toggleSelfieModal();
                  }
                }}
              />
            </div>
          </CustomModal>
        )}

        {bankAccountModal && (
          <CustomModal>
            <AddBankAccountForm
              toggleBankAccountModal={toggleBankAccountModal}
              saveBankAccount={saveBankAccount}
              bankAdding={bankAdding}
            />
            {/* <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Your Bank Account</h5>
                  <button
                    type="button"
                    className="tw-bg-transparent tw-border-0"
                    onClick={toggleBankAccountModal}
                  >
                    <span className="tw-cursor-pointer">
                      <AiOutlinePlus
                        size={24}
                        className="tw-transform tw-rotate-45"
                      />
                    </span>
                  </button>
                </div>
                <form onSubmit={saveBankAccount}>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Currency
                          </label>
                          <select
                            name="currency"
                            className="form-control wide"
                            onChange={(e) => setValue("currency", e.target.value)}
                            value={accountForm["currency"]}
                            required
                          >
                            <option value="">Select</option>
                            <option value="usd">USD</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">Country</label>
                          <select
                            name="country"
                            className="form-control wide"
                            onChange={(e) => setValue("country", e.target.value)}
                            value={accountForm["country"]}
                            required
                          >
                            <option value="">Select</option>
                            <option value="US">USA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Account Holder Name"
                            required
                            name="accountHolderType"
                            onChange={(e) =>
                              setValue("accountHolderName", e.target.value)
                            }
                            value={accountForm["accountHolderName"]}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Account Holder Type
                          </label>
                          <select
                            className="form-control wide"
                            required
                            name="accountHolderType"
                            onChange={(e) =>
                              setValue("accountHolderType", e.target.value)
                            }
                            value={accountForm["accountHolderType"]}
                          >
                            <option value="">Select</option>
                            <option value="individual">Individual</option>
                            <option value="company">Company</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Routing Number
                          </label>
                          <input
                            type="text"
                            name="routingNumber"
                            className="form-control"
                            placeholder="Routing Number"
                            required
                            onChange={(e) =>
                              setValue("routingNumber", e.target.value)
                            }
                            value={accountForm["routingNumber"]}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Account Number
                          </label>
                          <input
                            type="text"
                            name="accountNumber"
                            className="form-control"
                            placeholder="Account Number"
                            required
                            onChange={(e) =>
                              setValue("accountNumber", e.target.value)
                            }
                            value={accountForm["accountNumber"]}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="text-label form-label">
                            Confirm Account Number
                          </label>
                          <input
                            type="text"
                            name="confirmAccountNumber"
                            className="form-control"
                            placeholder="Confirm Account Number"
                            required
                            onChange={(e) =>
                              setValue("confirmAccountNumber", e.target.value)
                            }
                            value={accountForm["confirmAccountNumber"]}
                          />
                        </div>
                      </div>
                      <button
                        disabled={bankAdding}
                        type="submit"
                        className="btn btn-secondary mb-3"
                      >
                        {bankAdding ? "Please wait ..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
          </CustomModal>
        )}
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>

        {hostDetail &&
          (hostDetail.idVerificationStatus == 1 || AppConfig.ENABLE_SELFIE_AND_ID_VERIFICATION_CHECKPOINT===false) &&
          hostDetail.personalEmailVerification == 1 &&
          hostDetail.phoneVerification == 1 &&
          Boolean(addedBankAccount.length) &&
          hostDetail.payoutVerification === 1 && (
            <button
              onClick={onNext}
              type="button"
              className="btn btn-secondary mx-2 tw-mb-2"
              disabled={requesting}
            >
              {requesting ? "Adding ..." : "Review & Publish"}
            </button>
          )}
        {/* <button
          onClick={onNext}
          type="button"
          className="btn btn-secondary mx-2 tw-mb-2"
          disabled={requesting}
        >
          {requesting ? "Adding ..." : "Review & Publish"}
        </button> */}

        <button
          type="button"
          disabled={draftRequesting}
          onClick={saveAsDraft}
          className="btn btn-secondary mx-2 tw-mb-2"
        >
          {draftRequesting ? "Adding ..." : "Save as Draft"}
        </button>
      </div>
    </div>
  );
};
