import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import { payoutService } from "../../_services";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import ApexCharts from "react-apexcharts";

function CustomDashboard() {
  const [availablePayout, setAvailablePayout] = useState(0);
  const [isWalletFetching, setIsWalletFetching] = useState(false);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshKey, setRefreshKey] = useState(new Date());
  const [totalsentToPayout, settotalsentToPayout] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingSearchResult, setLoadingSearchResult] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState("last7DaysEarnings");
  const [graphData, setGraphData] = useState(null);


  const [graphFromDate, setGraphFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [graphToDate, setgraphToDate] = useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );

  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "Earnings",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return `$${value.toFixed(0)}`;
          },
        },
      },
    },
  });

  useEffect(() => {
    if (graphData) {
      const record = graphData.chartPoints;
      let dates = record.map((item) => {
        return moment( item.date).format('MM-DD-YYYY');
      });
      let earnings = record.map((item) => {
        return item.totalAmount;
      });
      console.log({ record, dates, earnings });

      setChartOptions({
        series: [{ name: "Earnings", data: earnings }],
        options: {
          ...chartOptions.options,
          xaxis: { categories: dates },
        },
      });
    }
  }, [selectedGraph, graphData]);

  useEffect(() => {
    fetchWalletInfo(page, searchValue);
  }, [page, refreshKey]);

  const fetchWalletInfo = async (page, searchValue) => {
    setIsWalletFetching(true);
    try {
      const result = await payoutService.getCustomDashboardDetails(
        page,
        searchValue
      );
      settotalsentToPayout(result.data.data.totalPayoutsSent);
      setAvailablePayout(result.data.data.availableForWithdraw);
      setWalletBalance(result.data.data.walletBalance);
      setWalletTransactions(result.data.data.transactions);
    } catch (error) {
      console.log(error);
    }
    setIsWalletFetching(false);
  };

  useEffect(() => {
    fetchGraphData();
  }, [selectedGraph, graphFromDate, graphToDate]);

  const fetchGraphData = async () => {
    try {
      const result = await payoutService.getGraphData({
        chartType: selectedGraph,
        graphFromDate,
        graphToDate,
      });
      console.log(result.data.data, "hostgraphdata");

      setGraphData(result.data.data.graphData);
    } catch (error) {
      console.log("fetchGraphData", error);
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  function formatAmount(amount) {
    return parseFloat(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const handleRequestPayout = async () => {
    try {
      const result = await payoutService.requestPayout();
      setRefreshKey(new Date());
      toast.success(result.data.message);
    } catch (error) {
      console.log("Request payout error", error.response);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleOnSearch = (e) => {
    setSearchValue(e.target.value);
  };

 

  useEffect(() => {
    setLoadingSearchResult(true);
    const getSearchResult = setTimeout(() => {
      fetchWalletInfo(page, searchValue);
      setLoadingSearchResult(false);
    }, 2000);

    return () => clearTimeout(getSearchResult);
  }, [searchValue]);

  return (
    <div>
      <ToastContainer />
      <Wrapper>
     
        {isWalletFetching === false ? (
          <>
            <div className="row justify-content-center gap-4 mb-5">
              <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 gap-3">
                <div className="tw-grid tw-grid-cols-1 lg:tw-col-span-1  md:tw-grid-cols-2 new-columes tw-flex-col tw-gap-2">
                  <div className="align-items-center tw-h-full gap-2 justify-content-center mb-3 d-flex flex-column p-4 cs-card cs-card-height w-4">
                    <p className="card-text">Total Sent to Payout Account</p>
                    <h5 className="card-title mb-0">
                      {totalsentToPayout.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </h5>
                  </div>

                 
                  
                  <div className="align-items-center d-flex flex-column gap-2 justify-content-center p-4 cs-card cs-card-height w-4">
                    <p className="card-text">Wallet Balance</p>
                    <h5 className="card-title">
                      {walletBalance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </h5>
                    <p className="card-text">Available for Withdraw</p>
                    <h5 className="card-title">
                      {availablePayout.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </h5>

                    <button
                      onClick={handleRequestPayout}
                      className="btn btn-secondary btn-sm py-1"
                    >
                      Request Payout
                    </button>
                  </div>
                </div>
                <div className="gap-2 lg:tw-col-span-2 p-4 cs-card">
                  <div>Total Earning Breakdown</div>
                  <div className="d-flex tw-text-center md:tw-text-left gap-4">
                    <div>
                      Last 7 days
                      <h5 className="card-title">
                        {graphData &&
                          parseFloat(
                            graphData.total.totalLast7Days
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </h5>
                    </div>
                    <div>
                      Last 30 days
                      <h5 className="card-title">
                        {graphData &&
                          parseFloat(
                            graphData.total.totalLast30Days
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </h5>
                    </div>
                    <div>
                      Year to Date
                      <h5 className="card-title">
                        {graphData &&
                          parseFloat(
                            graphData.total.totalLastYear
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-1 flex-grow-1">
                    <ApexCharts
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="area"
                      height={200}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <select
                        onChange={(e) => setSelectedGraph(e.target.value)}
                        name="daysFilter"
                        style={{
                          border: "none",
                          outline: "none",
                          background: "none",
                        }}
                      >
                        <option value="last7DaysEarnings">Last 7 Days</option>
                        <option value="last14DaysEarnings">Last 14 Days</option>
                        <option value="last30DaysEarnings">Last 30 Days</option>
                        <option value="last60DaysEarnings">Last 60 Days</option>
                        <option value="last6MonthEarnings">
                          Last 6 Months
                        </option>
                        <option value="lastYearEarnings">Last Year</option>
                        <option value="YTD">YTD</option>
                        <option value="custom">Custom</option>
                      </select>

                      {selectedGraph === "custom" && (
                        <>
                          <input
                            min={"2023-01-01"}
                            type="date"
                            className="mx-2"
                            value={graphToDate}
                            max={moment().format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setgraphToDate(e.target.value);
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                            }}
                          />
                          -
                          <input
                            type="date"
                            className="mx-2 "
                            value={graphFromDate}
                            max={moment().format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setGraphFromDate(e.target.value);
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div>Filter</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-between align-items-center mb-4">
              <h1 className="text-lg font-bold">Transactions</h1>
              <div
                className="input-group search-area"
                style={{
                  boxShadow: "0px 4px 18px 0px rgba(75, 70, 92, 0.1)",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={searchValue}
                  onChange={handleOnSearch}
                />
                <span className="input-group-text">
                  <a href="javascript:void(0)">
                    <i className="flaticon-381-search-2"></i>
                  </a>
                </span>
              </div>
            </div>

            <div className="table-responsive">
              <table
                className="table rounded-1   display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                id="guestTable-all"
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Reservation Id</th>
                    <th>Type</th>
                    <th>Guest</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {!isLoadingSearchResult ? (
                  <tbody>
                    {walletTransactions.map((curr, id) => (
                      <tr key={id}>
                        <td>{page * id + 1}</td>

                        <td>{curr.bookingId}</td>

                        <td>{curr.transactionType}</td>
                        <td>{curr.guestName}</td>
                        <td>
                          {moment(curr.createdAt).format("MMMM Do, YYYY")}
                        </td>

                        <td
                          // className="text-end"
                          style={{ paddingRight: 80 }}
                        >
                          {curr.status === "ADDED_TO_WALLET" && (
                            <span className="text-success">
                              {formatAmount(curr.amount)}
                            </span>
                          )}

                          {curr.status === "PAYOUT_COMPLETE" && (
                            <span className="text-black">
                              {formatAmount(curr.amount)}
                            </span>
                          )}

                          {curr.status === "PENALTY_FEE" && (
                            <span className="text-danger">
                              {formatAmount(curr.amount)}
                            </span>
                          )}

                          {curr.status === "REFUND_TO_GUEST" && (
                            <span className="text-danger">
                              {formatAmount(curr.amount)}
                            </span>
                          )}
                        </td>
                        <td>
                          {curr.status === "ADDED_TO_WALLET" && (
                            <span className="bg-cyan me-1 px-2 py-1 rounded-1 text-cyan">
                              Added To Wallet
                            </span>
                          )}

                          {curr.status === "PENALTY_FEE" && (
                            <span className="bg-danger-light me-1 px-2 py-1 rounded-1 text-danger">
                              Penalty Fee
                            </span>
                          )}

                          {curr.status === "PAYOUT_COMPLETE" && (
                            <span className="bg-success-light me-1 px-2 py-1 rounded-1 text-success">
                              Payout Complete
                            </span>
                          )}

                          {curr.status === "REFUND_TO_GUEST" && (
                            <span className="bg-danger-light me-1 px-2 py-1 rounded-1 text-danger">
                              Refund To Guest
                            </span>
                          )}
                        </td>

                        {/* <td className="">
                        {curr.paidDate
                          ? moment(curr.paidDate).format("MMMM Do, YYYY")
                          : "--"}
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr>
                    <td>{"Loading..."}</td>
                  </tr>
                )}
              </table>
            </div>

            {/* <div className="pagination"> */}
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <button onClick={handlePrevPage} class="page-link">
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button onClick={handleNextPage} class="page-link">
                  Next
                </button>
              </li>
            </ul>
            {/* </div> */}
          </>
        ) : (
          <div>Loading...</div>
        )}
      </Wrapper>
    </div>
  );
}

export default CustomDashboard;
