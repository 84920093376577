import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import CustomModal from "./../../components/ReactModal";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { propertyService, disputeService, userService } from "../../_services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import constant from "../../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
// import { addHours } from "date-fns";
import { nanoid } from "nanoid";
import moment from "moment";
import { feeService } from "../../_services";

import Modal from "react-modal";

const countdownRender = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return  <p></p>;//  <p>Expired</p>;
  } else {
    // Render a countdown
    return (
      <span>
        {/* {`${hours} hr `}
        {`${minutes} min `}
        {`${seconds} Sec`} */}
        {`${hours} : `}
        {`${minutes} : `}
        {`${seconds}`}
      </span>
    );
  }
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CreateDisputeModal = ({
  toggleDisputeModal,
  id,
  security,
  setRefreshKey,
}) => {
  const reasonRef = useRef();
  const [reason, setReason] = useState("");
  const [photos, setPhotos] = useState([]);
  const [amount, setAmount] = useState(null);
  const [requesting, setRequesting] = useState(false);
 

  const getImage = (file) => {
    let src = new FileReader();
    const url = URL.createObjectURL(file.current);
    return url;
  };

  const handleDelete = (id) => {
    const arr = photos.filter((curr) => curr.id !== id);
    setPhotos([...arr]);
  };

  function handleDispute(e) {
    e.preventDefault();
    setRequesting(true);
    if (!reason) {
      toast.error("Please add dispute reason.");
    }
    disputeService.add({ reason, photos, id, amount }).then(
      (res) => {
        if (res.status) {
          toast.success(res.message);
          setRefreshKey((state) => state + 1);
        } else {
          toast.error(res.message);
        }
        setRequesting(false);
        toggleDisputeModal();
      },
      (error) => {
        setRequesting(false);
      }
    );
  }
  return (
    <div className="modal-content tw-max-h-[100vh] md:tw-max-h-[95vh]   tw-overflow-scroll">
      <div className="modal-header tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-bg-white tw-z-10  ">
        <h5 className="modal-title">
          Create Dispute (security deposit -{" "}
          {security.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          )
        </h5>
        <button
          type="button"
          className="tw-bg-transparent tw-border-0"
          onClick={toggleDisputeModal}
        >
          <span className="tw-cursor-pointer">
            <AiOutlinePlus size={24} className="tw-transform tw-rotate-45" />
          </span>
        </button>
      </div>
      <form onSubmit={handleDispute}>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12 mb-2">
              <div className="tw-grid tw-grid-cols-3 md:tw-grid-cols-5 tw-gap-2 tw-items-start tw-flex-wrap tw-mb-4">
                {photos.map((curr, i) => (
                  <div
                    key={i}
                    id={curr.id}
                    className="tw-w-full tw-aspect-square tw-flex-shrink-0 tw-grow-0 tw-relative tw-cursor-move  tw-m-2 tw-object-cover tw-rounded-md tw-overflow-hidden"
                  >
                    <span
                      onClick={() => handleDelete(curr.id)}
                      className="tw-absolute tw-right-4 tw-top-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full tw-bg-gray-500 tw-bg-opacity-50"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45 tw-text-white"
                      />
                    </span>
                    <img
                      className="tw-w-full tw-h-full tw-object-cover "
                      src={getImage(curr)}
                    />
                  </div>
                ))}{" "}
              </div>
              <div className="col-lg-6 mb-2">
                <div className="mb-3">
                  <label className="text-label form-label">Add photos</label>
                  <div className="tw-flex tw-items-center">
                    <label
                      htmlFor="add_photos"
                      className="btn btn-secondary btn-sm"
                    >
                      choose
                    </label>
                    <p className="tw-text-base tw-ml-4">
                      {!photos.length
                        ? "No file choosen"
                        : `${photos.length} File choosen`}
                    </p>
                  </div>
                  <input
                    id="add_photos"
                    type="file"
                    multiple
                    className="form-control tw-hidden"
                    accept="image/*"
                    onChange={(e) => {
                      const curr = Array.from(e.target.files).map((file, i) => {
                        return {
                          current: file,
                          id: nanoid(),
                          order: photos.length + i + 1,
                        };
                      });

                      setPhotos([...photos, ...curr]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label">Amount</label>
                <input
                  step="0.01"
                  name="amount"
                  type="number"
                  max={security}
                  min={0}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="text-label form-label">Dispute Reason</label>
                <textarea
                  ref={reasonRef}
                  rows={10}
                  type="text"
                  name="routingNumber"
                  className="form-control"
                  value={reason}
                  onChange={function(e) {
                    setReason(e.target.value);
                    reasonRef.current.style.height =
                      reasonRef.current.scrollHeight + "px";
                  }}
                  required
                ></textarea>
              </div>
            </div>

            <button type="submit" className="btn btn-secondary mb-3">
              {requesting ? "Please wait..." : "Create Dispute"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default function ReservationList(props) {
  const history = useHistory();
  const [sorting, setSorting] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [requesting, setRequesting] = useState(true);
  const [filters, setFilters] = useState({});
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [disputeModal, setDisputeModal] = useState(false);
  const [disputeBookingId, setDisputeBookingId] = useState(null);
  const [securityAmount, setSecurityAmount] = useState(0);
  const [cancelReservationId, setCancelReservationId] = useState(null);
  const [crewmatesFlatPenalty, setCrewmatesFlatPenalty] = useState(0);
  const today = new Date();
  const [showDateSelpopup, setShowDateSelpopup] = useState(false);
  const [isCancelByAdmin, setIsCancelByAdmin] = useState(true);
  const [cancelDate, setCancelDate] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [isCancelAvailable, setIsCancelAvailable] = useState(false);
  const [isRejectText, setIsrejectText] = useState(false);
  
  

  const [hostDetail, setHostDetail] = useState(null);

  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  useEffect(() => {
    userService.getDetail({}).then(
      (objS) => {
        if (objS.status) {
          setHostDetail(objS.data);
        }
      },
      (error) => {}
    );
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenVerification, setIsOpenVerification] = React.useState(false);
  const [modalIsOpenReject, setIsOpenReject] = React.useState(false);
  const [bookingId, setBookingId] = React.useState("");
  const [reasonReject, setReasonReject] = React.useState(false);
  const [error, setError] = useState("");
  function openModal() {
    setIsOpen(true);
  }
  function openModalReject() {
    setIsOpenReject(true);
  }
  function openModalVerification() {
    setIsOpenVerification(true);
  }

  function afterOpenModal() {}
  function afterOpenModalVerification() {}
  function afterOpenModalReject() {}

  function closeModal() {
    setIsOpen(false);
  }
  function closeModalVerification() {
    setIsOpenVerification(false);
  }
  function closeModalReject() {
    setIsOpenReject(false);
  }

  const toggleCancelModal = async (id = null, booking = []) => {
    console.log("booking", booking);
    if (!!booking.id) {
      setBookingData(booking);
      const checkInDate = new Date(booking.checkInDate)
        .toISOString()
        .split("T")[0];
      const checkInDateFormat = moment(checkInDate).valueOf();
      const currentDateFormat = moment().valueOf();
      console.log("moment()", moment());
      console.log("checkInDate", moment(checkInDate));
      if (currentDateFormat > checkInDateFormat) {
        setIsCancelAvailable(true);
        console.log("checkInDateFormat", checkInDateFormat);
      } else {
        console.log("checkInDateFormat", checkInDateFormat);
        setIsCancelAvailable(false);
      }
    }

    if (id) {
      await getCrewmatesFlatPenalty();
    }

    setCancelReservationId((e) => (e !== null ? null : id));
  };

  const handleSearch = (e) => {
    setPage(1);
    setSearch(e.target.value);
    handleFliters("search", e.target.value);
  };

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const handleFliters = (key, value) => {
    if (value) {
      setFilters((e) => {
        const newFilter = { ...e, [key]: value };
        return newFilter;
      });
    } else {
      setFilters((e) => {
        const newFilter = { ...e };
        delete newFilter[key];
        return newFilter;
      });
    }
  };

  const handleRejectBooking = async (status, id) => {
      setIsOpenReject(true);
      setBookingId(id);
      return;
  }

  const handleBooking = async (status, id, booking) => {
    if (!hostDetail) {
      return;
    }
    

    if (hostDetail.payoutVerification !== 1 && status === "Accepted") {
      console.log("Please add payout details");
      setIsOpen(true);
      return;
    }

    if (hostDetail.idVerificationStatus !== 1 && status === "Accepted") {
      console.log("Please Self Verify");
      setIsOpenVerification(true);
      return;
    }
    try {
      
      if (status === "Rejected") {
        const res = await propertyService.updateBookingStatus({
          id: id,
          status,
          reason: reasonReject,
        });
        if (status === "Cancelled") {
          await toggleCancelModal();
        }
        if (res.status) {
          toast.success(res.message);
          setRefreshKey((e) => e + 1);
        } else toast.error(res.message);
      }
      else{
        const res = await propertyService.updateBookingStatus({
          id: id,
          status,
        });
        if (status === "Cancelled") {
          await toggleCancelModal();
        }
        if (res.status) {
          toast.success(res.message);
          setRefreshKey((e) => e + 1);
        } else toast.error(res.message);

      }
      

      
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  function renderActionChip(status, id, booking) {
    switch (status) {
      case "Pending": {
        return (
          <>
            <li
              onClick={() => handleBooking("Accepted", id)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 tw-rounded-md tw-text-center p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Accept
            </li>
            <li
              onClick={() => handleRejectBooking("Rejected", id)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 tw-rounded-md tw-text-center p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Reject
            </li>
          </>
        );
      }
      case "Accepted": {
        return (
          <>
            {/* <li
              onClick={async () => await toggleCancelModal(id, booking)}
              className=" tw-cursor-pointer tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
            >
              Cancel
            </li> */}
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  }

  function handleFilterReset() {
    history.go(0);
  }

  function handleReason(e) {
    if(e.value === "Other"){
      setIsrejectText(true);
    }
    else{
      setIsrejectText(false);
      setReasonReject(e.value);
    }
  }

  function toggleDisputeModal() {
    setDisputeModal((e) => !e);
  }

  function showDisputeButton(status, checkout, isDisputeCreated) {
    if (status === "Completed" && !isDisputeCreated) {
      var a = moment(new Date());
      var b = moment(new Date(checkout));

      const days = a.diff(b, "days");
      const hours = a.diff(b, "hours");
      console.log("days b =>>> ", b, hours);
      // if (days <= 31) {
      //   return true;
      // } else return false;
      if (hours <= 48) {
        return true;
      } else return false;
    } else return false;
  }

  async function cancelBooking(bookingId) {
    try {
      const response = await propertyService.cancelBookingByHost({ bookingId });
      toast.success(response.message);
      await toggleCancelModal();
      setRefreshKey((e) => e + 1);
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    propertyService.allList({ page, limit: 10 }).then(
      (objS) => {
        setPropertyList(objS.data);
      },
      (error) => {
        toast.error("Something went wrong while fetching properties.");
      }
    );
  }, []);

  useEffect(() => {
    const arr = list.sort(function(a, b) {
      var nameA = a.propertyId.listingName.toLowerCase();
      var nameB = b.propertyId.listingName.toLowerCase();
      if (nameA > nameB) return 1;
      if (nameA < nameB) return -1;
      return 0;
    });

    setList([...arr]);
  }, [sorting]);

  useEffect(() => {
    setRequesting(true);
    propertyService.bookinglist({ page, limit: 10, ...filters }).then(
      (objS) => {
        let bookings = objS.data.docs;

        if (sorting) {
          const arr = bookings.sort(function(a, b) {
            var nameA = a.propertyId.listingName.toLowerCase();
            var nameB = b.propertyId.listingName.toLowerCase();
            if (nameA > nameB) return 1;
            if (nameA < nameB) return -1;
            return 0;
          });
          setList(arr);
        } else setList(bookings);

        setTotal(objS.data.total);
        setRequesting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setRequesting(false);
      }
    );
  }, [refreshKey, filters]);

  const getCrewmatesFlatPenalty = async () => {
    const { data } = await feeService.getFee();

    if (data.length) {
      setCrewmatesFlatPenalty(
        parseFloat(data[data.length - 1].crewmatesFlatPenaltyFee) || 0
      );
    }
  };

  // function for cancellation date selection

  const handleCancelDatePopup = (status, isAdmin) => {
    setShowDateSelpopup(status);
    //   if (status) {
    //    setCancelDate("");
    //    setIsCancelByAdmin(isAdmin);
    //  }
  };

  const handleCancelDate = (cancelDateVal) => {
    setCancelDate(cancelDateVal);
  };

  const cancelBookingFutureDate = async (id) => {
    console.log("Booking Data", bookingData);

    try {
      const res = await propertyService.cancelBookingByDate(
        id,
        cancelDate,
        false
      );

      if (res.status) {
        toast.success(res.message);
        setRefreshKey((e) => e + 1);
        setCancelReservationId(null);
        setShowDateSelpopup(false);
      } else toast.error(res.message);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleRejectBookingConfirm = (status) => 
  {
    
     // Validation: Ensure a reason is selected or provided
    if (reasonReject == false || reasonReject == "false" ) {
      setError("Please select or provide a reason to reject the booking.");
      return;
    }

    // Clear error and proceed
    setError("");
    setIsOpenReject(false);
    handleBooking(status, bookingId);
  }

  const getNoticeText = (data) => {
    // (Notice Applied by hostname/Crewmates)
    switch (data.noticeBy) {
      case "Host":
        return `(Notice Applied by ${data.hostId.name})`;
      case "Guest":
        return `(Notice Applied by ${data.userId.name})`;
      case "Admin":
        return `(Notice Applied by Crewmates)`;
      default:
        return "";
    }
  };

  const showStausMessagesWithNotice = (data) => {
    if (data.isNotice) {
      switch (data.status) {
        case "Cancelled":
        {
          if (data.bookingCancelledBy == "Host") {
            return `Cancelled by Host`;
          } else if (data.bookingCancelledBy == "Crewmates") {
            return `Cancelled`;
          } else if (data.bookingCancelledBy == "Guest") {
            return `Cancelled by Guest`;
          }
          else{
            return `Cancelled`;
          }
        }
        case "Rejected":
        {
          return `Rejected`;
        }
        case "Accepted":
          return `Accepted ${getNoticeText(data)}`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled ${getNoticeText(data)}`;
        default:
          return data.status;
      }
    } 
    else {
      switch (data.status) {

        case "Cancelled":
        {
          if (data.bookingCancelledBy == "Host") {
            return `Cancelled by Host`;
          } else if (data.bookingCancelledBy == "Crewmates") {
            return `Cancelled`;
          } else if (data.bookingCancelledBy == "Guest") {
            return `Cancelled by Guest`;
          }
          else{
            return `Cancelled`;
          }
        }
        case "Rejected":
        {
         
          return `Rejected`;
          
        }
        case "Accepted":
          // case "Paid":
          return `Accepted`;
        case "Cancel_Scheduled":
          return `Cancel Scheduled`;
        default:
          return data.status;
      }
    }
   
  };

  const openMessages = (booking) => {
    const propertyData = booking.propertyId;
    const hostData = booking.hostId;
    const userData = booking.userId;
    history.push({
      pathname: '/message',
      state: {
          channelId: `${propertyData._id}-${userData.id}-${hostData.id}-${booking.bookingId}`,
          friendlyName: `${propertyData._id}-${userData.id}-${hostData.id}-${booking.bookingId}`,
          property: {
              name: propertyData.listingName,
              id: propertyData._id,
              host: hostData.name,
              user: userData.name,
              picture: propertyData.images[0],
              reservationId: booking.bookingId
          },
          user: {
              id: userData.id,
              name: userData.name,
              profile: userData.avatar,
              email: userData.personalEmail,
          },
          host: {
              id: hostData.id,
              name: hostData.name,
              profile: hostData.avatar,
              email: hostData.personalEmail,
          },
      },
  })
  }

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar
        user={decoded}
        showSearch={true}
        handleSearch={handleSearch}
        title="Reservation List"
        search={search}
      />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      // REJECT Reservation
      <Modal
        isOpen={modalIsOpenReject}
        onAfterOpen={afterOpenModalReject}
        onRequestClose={closeModalReject}
        style={customStyles}
        contentLabel="Self Reject"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Reject Booking</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModalReject}
            ></button>
          </div>
          <div className="modal-body">
          <label htmlFor="reason" className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold">
            Select reason to reject reservation *
          </label>
          <select
            onChange={(e) => handleReason( e.target)}
            className="form-control form-control-sm  wide"
            name="rentalType"
            required
          >
            <option value="">Select</option>
            <option value={"No beds are available for the selected dates"}>No beds are available for the selected dates</option>
            <option value={"The property is discontinuing its rental business"}>The property is discontinuing its rental business</option>
            <option value={"The guest is making the booking on behalf of another person"}>The guest is making the booking on behalf of another person</option>
            <option value={"Special requests that the Host cannot accommodate"}>Special requests that the Host cannot accommodate  </option>
            <option value={"Other"}>Other</option>
          </select>
          <br></br>
          {error && <p className="tw-text-sm tw-text-red-500 tw-mt-2">{error}</p>}
          { isRejectText ?
          <div>
          <label htmlFor="reason" className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold">  
          Enter your reason to reject reservation *  
        </label>
        <input
            id="reasonReject"
            type="text"
            className="form-control form-control-sm"
            onChange={(e) => {
              if (e.target.value.length <= 225) {
                setReasonReject(e.target.value);
              }
            }}
            placeholder="Enter your reason here..."
          />
          <span className="tw-text-sm tw-text-gray-500">
            {reasonReject.length}/225 characters
          </span>
        </div>
        :
        <>
        </>

          }
          
          </div>
          <div className="mt-4 tw-flex tw-justify-end tw-gap-4">
        <button onClick={closeModalReject} 
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary tw-rounded-md tw-text-white">
          Cancel
        </button>
        <button onClick={() => handleRejectBookingConfirm("Rejected")}
        className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-border-none tw-text-xs btn-secondary tw-rounded-md tw-text-white"
        >
          Reject Booking
        </button>
      </div>
        </div>
      </Modal>
      //Reject reservation

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Enable Payout"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Enable Payout</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            <h4>
              Please indicate your preferred payment method for this booking.
            </h4>
          </div>
          <div className="modal-footer d-flex gap-2 justify-content-center">
            {/* <button className="btn btn-secondary" onClick={closeModal}>
              Close
            </button> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                closeModal();
                history.push("/setting/payout-method?redirect=/reservation");
              }}
            >
              Finish Setup
            </button>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={modalIsOpenVerification}
        onAfterOpen={afterOpenModalVerification}
        onRequestClose={closeModalVerification}
        style={customStyles}
        contentLabel="Self Verification"
        ariaHideApp={false}
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title">Id Verification</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModalVerification}
            ></button>
          </div>
          <div className="modal-body">
            <h4>
            As part of our ongoing commitment to security and compliance, <br></br>we kindly ask you to reverify your identification <br></br>prior to accepting this booking request.
            </h4>
          </div>
          <div className="modal-footer d-flex gap-2 justify-content-center">
            {/* <button className="btn btn-secondary" onClick={closeModal}>
              Close
            </button> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                closeModalVerification();
                history.push("/profile");
              }}
            >
              Finish Verification
            </button>
          </div>
        </div>
      </Modal>


      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="tw-text-xl tw-font-medium">Filter</h4>
              <div className="tw-grid tw-grid-cols-2  md:tw-grid-cols-6 tw-gap-2 tw-mb-4">
                <div>
                  <label className="text-label form-label">Check In</label>
                  <input
                    onChange={(e) => handleFliters("start", e.target.value)}
                    type="date"
                    name="checkInData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                <div>
                  <label className="text-label form-label">Check Out</label>
                  <input
                    onChange={(e) => handleFliters("end", e.target.value)}
                    type="date"
                    name="checkOutData"
                    className="form-control form-control-sm"
                    placeholder="Check In"
                  />
                </div>
                {/* <div>
                  <label className="form-label">Rental Type</label>
                  <select
                    onChange={(e) => handleFliters("pt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Crashpad">Crashpad</option>
                    <option value="Entire Place">Entire Place</option>
                  </select>
                </div> */}
                <div>
                  <label className="form-label">Listing Name</label>
                  <select
                    onChange={(e) => handleFliters("ppt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">All</option>
                    {propertyList.map((curr) => (
                      <option value={curr.id} key={curr._id}>
                        {curr.listingName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Booking Type</label>
                  <select
                    onChange={(e) => handleFliters("bt", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Hot bed">Hot Bed</option>
                    <option value="Cold bed">Cold Bed</option>
                  </select>
                </div>
                <div>
                  <label className="form-label">Status</label>
                  <select
                    onChange={(e) => handleFliters("s", e.target.value)}
                    className="form-control form-control-sm  wide"
                    name="rentalType"
                    required
                  >
                    <option value="">Select</option>
                    <option value={"Accepted"}>Accepted</option>
                    {/* <option value={"Upcoming"}>Upcoming</option> */}
                    <option value={"Pending"}>Pending</option>
                    <option value={"Completed"}>Completed</option>
                    <option value={"Rejected"}>Rejected</option>
                    <option value={"Expired"}>Expired</option>
                    <option value={"Cancelled"}>Cancelled</option>
                  </select>
                </div>
                <div>
                  <label className="text-label form-label">Reset Filter</label>
                  <button
                    onClick={handleFilterReset}
                    className="btn-sm btn btn-primary form-control form-control-sm"
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className="tw-hidden tw-items-center my-4">
                <input
                  checked={sorting}
                  onChange={() => {
                    setSorting(true);
                  }}
                  type="checkbox"
                  name=""
                  id="sort"
                  className="tw-mr-2 tw-h-4 tw-w-4"
                />
                <label className="tw-block tw-mb-0" htmlFor="sort">
                  sort by listing name
                </label>
              </div>
              {requesting && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
              {disputeModal && (
                <CustomModal>
                  <CreateDisputeModal
                    id={disputeBookingId}
                    security={securityAmount}
                    setRefreshKey={setRefreshKey}
                    toggleDisputeModal={toggleDisputeModal}
                  />
                </CustomModal>
              )}

              {cancelReservationId && (
                <CustomModal>
                  <div className="tw-bg-white tw-mx-auto tw-rounded-md tw-p-4">
                    <p className="tw-text-sm ">
                      In an effort to provide a consistent experience for crew,
                      there is a ${crewmatesFlatPenalty} host cancellation
                      penalty. Are you sure you want to cancel the reservation?
                    </p>
                    <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
                      <button
                        onClick={async () => await toggleCancelModal()}
                        className="btn btn-sm btn-secondary"
                      >
                        Back
                      </button>
                      <button
                        onClick={() =>
                          isCancelAvailable && bookingData.numberOfDay > 1
                            ? handleCancelDatePopup(true, false)
                            : cancelBooking(cancelReservationId)
                        }
                        //onClick={() => cancelBooking(cancelReservationId)}
                        className="btn btn-sm btn-primary"
                      >
                        Cancel Booking
                      </button>
                    </div>
                  </div>
                </CustomModal>
              )}

              {showDateSelpopup && (
                <CustomModal>
                  <div
                    className="tw-bg-white tw-mx-auto tw-rounded-md tw-p-4 kk"
                    style={{ width: "60%" }}
                  >
                    <div className="tw-flex tw-items-center">
                      <p className="tw-text-sm ">
                        Your booking will be canceled on:
                      </p>
                      <div className="tw-grid tw-grid-cols-1 mx-2">
                        <input
                          onChange={(e) => handleCancelDate(e.target.value)}
                          type="date"
                          min={
                            !!bookingData.checkInDate
                              ? new Date(bookingData.checkInDate)
                                  .toISOString()
                                  .split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          /*                          max={!!bookingData.checkOutDate ? new Date(bookingData.checkOutDate).toISOString().split('T')[0] :  moment().clone().add(1 , "M").startOf('month').format('YYYY-MM-DD')} */

                          max={
                            !!bookingData.checkOutDate
                              ? new Date(
                                  new Date(bookingData.checkOutDate).setDate(
                                    new Date(
                                      bookingData.checkOutDate
                                    ).getDate() - 1
                                  )
                                )
                                  .toISOString()
                                  .split("T")[0]
                              : moment()
                                  .clone()
                                  .add(1, "M")
                                  .startOf("month")
                                  .format("DD-MM-YYYY")
                          }
                          name="cancelDate"
                          // value={cancelDate}
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control form-control-sm h-auto"
                          placeholder="dd-mm-yyyy"
                        />
                      </div>
                    </div>

                    <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2 py-3">
                      <button
                        onClick={() => handleCancelDatePopup(false, true)}
                        className="btn btn-sm btn-secondary"
                      >
                        Back
                      </button>
                      <button
                        disabled={!cancelDate}
                        onClick={() =>
                          cancelBookingFutureDate(cancelReservationId)
                        }
                        className="btn btn-sm btn-primary"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </CustomModal>
              )}

              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Status</th>
                          {/* <th>Payout Method</th> */}
                          <th>Reservation Id</th>
                          <th>Guest Name</th>
                          <th>Listing Name</th>
                          <th>Booking Type</th>
                          <th>Room Name</th>
                          <th>Bed Name</th>
                          <th>Total Amount</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((booking) => (
                          <tr key={booking.id}>
                            <td>
                              <div>
                                <div className="flex  tw-space-y-1 tw-flex-col">
                                  <Link to={`/reservation/${booking.id}`}>
                                    <li className="tw-text-center tw-rounded-md  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white">
                                      View
                                    </li>
                                  </Link>
                                    <li onClick={() => openMessages(booking)} className="tw-cursor-pointer tw-text-center tw-rounded-md  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white">
                                      Message
                                    </li>
                                  {/* <li
                                    onClick={() => {
                                      setReportBookingId(booking.id);
                                      toggleReportModal();
                                    }}
                                    className="tw-cursor-pointer  tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                                  >
                                    Report
                                  </li> */}
                                  {booking.disableDispute === false &&
                                    showDisputeButton(
                                      booking.status,
                                      booking.checkOutDate,
                                      booking.isDisputeCreated
                                    ) && (
                                      <li
                                        onClick={() => {
                                          if (booking.disableDispute) return;
                                          setDisputeBookingId(booking.id);
                                          setSecurityAmount(
                                            booking.propertyId.securityDeposite
                                          );
                                          toggleDisputeModal();
                                        }}
                                        // ${true?'tw-bg-[#4d5e70]':'tw-bg-primary'}
                                        style={{ background: "#22426E" }}
                                        // style={{background:booking.disableDispute === true?'#4d5e70':'#22426E'}}
                                        className={`tw-cursor-pointer tw-rounded-md  tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs  tw-text-white `}
                                      >
                                        Create Dispute
                                      </li>
                                    )}
                                  {booking.isDisputeCreated && (
                                    <Link
                                      to={`/dispute/details/${booking.disputeId}`}
                                    >
                                      <li className="tw-cursor-pointer tw-rounded-md tw-mt-1  tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white">
                                        View Dispute
                                      </li>
                                    </Link>
                                  )}
                                  {renderActionChip(
                                    booking.status,
                                    booking._id,
                                    booking
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="fs-16">
                                {/* {booking.status} */}
                                {showStausMessagesWithNotice(booking)}
                                {booking.status === "Pending" && (
                                  <p className="tw-text-[14px] w-max">
                                    {
                                      <Countdown
                                        renderer={countdownRender}
                                        // date={addHours(
                                        //   new Date(booking.createdAt),
                                        //   24
                                        // )}
                                        date={moment(booking.createdAt).add(24, "hour")}
                                      />
                                    }
                                  </p>
                                )}
                              </span>
                            </td>
                            {/* <td>
                              <span className="fs-16">
                                {booking.isMonthlyCheckout === 1
                                  ? "Current month"
                                  : "Booked for future month"}{" "}
                              </span>
                            </td> */}
                            <td>
                              <span className="fs-16">
                                {booking.bookingId || "--"}{" "}
                              </span>
                            </td>

                            <td>
                              <span className="fs-16">
                                {booking.userId.name}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.listingName}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {" "}
                                {booking.propertyId.bookingType === "Flexible"
                                  ? `Flexible ${booking.bookingType}`
                                  : booking.bookingType}{" "}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">{booking.roomName}</span>
                            </td>

                            <td>
                              <span className="fs-16">
                                {booking.propertyType[0] === "Crashpad"
                                  ? booking.bedName
                                  : "--"}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.wholeBookingTotal}
                                {/* {Math.round(booking.totalPrice)} */}
                              </span>
                            </td>

                            <td>
                              <span className="fs-16">
                                {moment(
                                  booking.checkInDate.split("T")[0]
                                ).format("MMMM Do, YYYY")}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {booking.checkOutDate
                                  ? moment(
                                      booking.checkOutDate.split("T")[0]
                                    ).format("MMMM Do, YYYY")
                                  : "--"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
