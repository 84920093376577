import React, { useState } from "react";
import Tippy from "@tippy.js/react";
import { BsPlusLg, BsTrash, BsPencilSquare, BsCheck2All } from "react-icons/bs";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { propertyService } from "../../../_services";

var Modal = require("react-bootstrap-modal");

export const SleepingArrangements = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  saveAsDraft,
  draftRequesting,
}) => {
  const [roomList, setRoomList] = useState(
    formData["sleepingArrangements"] || []
  );
  const [stepForm, setStepForm] = useState({});
  const [beds, setBeds] = useState([]);

  const [bedNumber, setBedNumber] = useState("");
  const [perNightPrice, setNightPrice] = useState("");
  const [perMonthPrice, setMonthPrice] = useState("");
  const [bedType, setBedType] = useState("");

  const [editBedNumber, setEditBedNumber] = useState("");
  const [editPerNightPrice, setEditNightPrice] = useState("");
  const [editPerMonthPrice, setEditMonthPrice] = useState("");
  const [editBedType, setEditBedType] = useState("");

  const [isEdit, setEdit] = useState(false);
  const [isEditBed, setEditBed] = useState(false);

  const [index, setIndex] = useState("");
  const [editBedIndex, setEditBedIndex] = useState("");

  const [numberOfBathroom, setBathroom] = useState(0);

  const handleBed = () => {
    if (!stepForm.numberOfBeds) return;
    if (beds.length == stepForm.numberOfBeds) return;

    var setObj = { bedNumber, bedType };
    if (formData.rentalType === "Crashpad") {
      if (!bedNumber) return;
      if (beds.length) {
        if (beds.some((bed) => bed.bedNumber === bedNumber)) {
          toast.info("Bed number must be unique.");
          return;
        }
      }
    }
    if (perNightPrice) setObj["perNightPrice"] = perNightPrice;
    if (perMonthPrice) setObj["perMonthPrice"] = perMonthPrice;

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Hot bed" ||
        formData.bookingType === "Flexible") &&
      !perNightPrice
    ) {
      return;
    }

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Cold bed" ||
        formData.bookingType === "Flexible") &&
      !perMonthPrice
    ) {
      return;
    }

    if (!bedType) {
      return;
    }

    setBeds([...beds, setObj]);
    setBedNumber("");
    setBedType("");
    setNightPrice("");
    setMonthPrice("");
  };

  const deleteSleepingArrangement = async (room, index) => {
    try {
      const result = await propertyService.isRoomDeletableService(room._id);
      if (result.data && result.data.isDeletable) {
        const updatedRoomList = roomList.filter(
          (item) => item._id !== room._id
        );
        setRoomList(updatedRoomList);
      } else {
        toast.warn("Booking exists for this room, Deletion is not permitted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeBed = (indx) => {
    var b = beds.filter((value, idx) => idx != indx);
    console.log(roomList);
    try {
      roomList.map((room, i) => {
        if (index === i) {
          room.beds = b;
        }
      });
      setRoomList([...roomList]);
    } catch (error) {
      console.log(error, "error removing bed");
    }
    setBeds(b);
  };

  const editBed = (index, bed) => {
    setEditBed(true);
    setEditBedIndex(index);
    var cloneBed = Object.assign({}, bed);
    cloneBed.bedNumber && setEditBedNumber(cloneBed.bedNumber);
    cloneBed.bedType && setEditBedType(cloneBed.bedType);
    cloneBed.perNightPrice && setEditNightPrice(cloneBed.perNightPrice);
    cloneBed.perMonthPrice && setEditMonthPrice(cloneBed.perMonthPrice);
  };

  const updatedBed = () => {
    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Hot bed" ||
        formData.bookingType === "Flexible") &&
      !editPerNightPrice
    ) {
      return;
    }

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Cold bed" ||
        formData.bookingType === "Flexible") &&
      !editPerMonthPrice
    ) {
      return;
    }

    if (!editBedType) {
      return;
    }

    if (formData.rentalType === "Crashpad" && !editBedNumber) {
      return;
    }
    var updatedBeds = [];
    var remainingBeds = beds.filter((value, idx) => idx != editBedIndex);
    if (remainingBeds.length && formData.rentalType === "Crashpad") {
      if (remainingBeds.some((bed) => bed.bedNumber === editBedNumber)) {
        toast.info("Bed number must be unique.");
        return;
      }
    }

    beds.forEach((bed, idx) => {
      if (idx == editBedIndex) {
        var setObj = { bedNumber: editBedNumber.trim(), bedType: editBedType };
        if (editPerNightPrice) setObj["perNightPrice"] = editPerNightPrice;
        if (editPerMonthPrice) setObj["perMonthPrice"] = editPerMonthPrice;
        updatedBeds.push(setObj);
      } else updatedBeds.push(bed);
    });
    setBeds(updatedBeds);
    setEditBedNumber("");
    setEditBedType("");
    setEditNightPrice("");
    setEditMonthPrice("");
    setEditBed(false);
    setEditBedIndex("");
  };

  const handleRoom = (e) => {
    console.log(stepForm, "handleRoom");
    e.preventDefault();
    if (beds.length == 0) return;
    // if (numberOfBathroom == 0) return;
    if (beds.length != stepForm.numberOfBeds) {
      toast.info("Number of beds and added beds must be same.");
      return;
    }
    if (
      stepForm["roomType"] === "Shared" &&
      parseInt(stepForm["numberOfBeds"]) === 1
    ) {
      toast.info("Number of beds must be greater than 1 for shared rooms.");
      return;
    }

    if (formData.rentalType === "Crashpad" && !validateCrashpadRoomBeds(beds)) {
      toast.info("Please fill beds property");
      return;
    }

    function validateCrashpadRoomBeds(beds = []) {
      return (
        beds.filter((bed) => bed.bedNumber.trim().length === 0).length === 0
      );
    }

    if (isEdit) {
      var updatedRoomList = [];
      roomList.forEach((room, idx) => {
        if (idx == index)
          updatedRoomList.push({
            ...stepForm,
            numberOfBathroom: numberOfBathroom,
            beds,
          });
        else updatedRoomList.push(room);
      });
      setRoomList(updatedRoomList);
      setFormData({
        ...formData,
        sleepingArrangements: updatedRoomList,
      });
    } else {
      console.log("else", roomList);
      setRoomList([
        ...roomList,
        { ...stepForm, numberOfBathroom: numberOfBathroom, beds },
      ]);
      setFormData({
        ...formData,
        sleepingArrangements: [
          ...roomList,
          { ...stepForm, numberOfBathroom: numberOfBathroom, beds },
        ],
      });
    }

    setBeds([]);
    setBedNumber("");
    setBedType("");
    setNightPrice("");
    setMonthPrice("");
    setStepForm({});
    console.log("1477");
    document.body.classList.remove("modal-open");
    window.$("#addRoomModal").modal("hide");
    window.$(".modal-backdrop").remove();
  };

  const editSleepingArrangement = (room, index) => {
    setEdit(true);
    setIndex(index);
    var cloneRoom = Object.assign({}, room);
    setBeds(cloneRoom.beds);
    delete cloneRoom.beds;
    setStepForm(cloneRoom);
    setBathroom(cloneRoom.numberOfBathroom);
    window.$("#addRoomModal").modal("show");
  };
  const addSleepingArrangement = () => {
    setEdit(false);
    setBeds([]);
    setStepForm({});
    setBathroom(0);
    window.$("#addRoomModal").modal("show");
  };

  const renderBedlist = () => {
    return beds.map((curr, ind) => {
      if (ind !== editBedIndex) {
        return (
          <tr key={ind}>
            {formData.rentalType === "Crashpad" && <td>{curr.bedNumber}</td>}
            <td>{curr.bedType}</td>
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <td>{curr.perNightPrice}</td>
              )}
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <td>{curr.perMonthPrice}</td>
              )}
            <td>
              <div className="tw-w-max">
                {editBedIndex !== ind && (
                  <div className="tw-grid  tw-grid-cols-2 tw-gap-2">
                    <button
                      onClick={() => editBed(ind, curr)}
                      type="button"
                      className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none mx-2"
                    >
                      <BsPencilSquare size={15} />
                    </button>
                    <button
                      onClick={() => removeBed(ind)}
                      type="button"
                      className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none"
                    >
                      <BsTrash size={15} />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            {formData.rentalType === "Crashpad" && (
              <th>
                <div>
                  <input
                    onChange={(e) => {
                      if (editBedNumber.trim().length > 0) {
                        setEditBedNumber(e.target.value);
                      } else {
                        setEditBedNumber(e.target.value.trim());
                      }
                    }}
                    value={editBedNumber}
                    type="text"
                    className="form-control"
                    placeholder="Bed Number"
                    required
                  />
                </div>
              </th>
            )}
            <th>
              <div>
                <select
                  name="bed_type"
                  onChange={(e) => setEditBedType(e.target.value)}
                  value={editBedType}
                  className="form-control wide"
                  required
                >
                  <option value="">Select</option>
                  <option value="King">King</option>
                  <option value="Queen">Queen</option>
                  <option value="Twin">Twin</option>
                  <option value="Couch">Couch</option>
                  <option value="Top bunk">Top bunk</option>
                  <option value="Bottom bunk">Bottom bunk</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </th>

            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <th>
                  <div>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          setEditNightPrice(
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        }
                      }}
                      value={editPerNightPrice}
                      placeholder="Per night price"
                      maxLength={9}
                      min={1}
                      max={999999999}
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </th>
              )}
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <th>
                  <div>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          setEditMonthPrice(
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        }
                      }}
                      value={editPerMonthPrice}
                      type="number"
                      className="form-control"
                      placeholder="Per month price"
                      maxLength={9}
                      min={1}
                      max={999999999}
                      required
                    />
                  </div>
                </th>
              )}

            <th>
              <div>
                <button
                  onClick={updatedBed}
                  type="button"
                  className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                >
                  <BsCheck2All size={20} />
                </button>
              </div>
            </th>
          </tr>
        );
      }
    });
  };

  const handleStep = (tag, value) => {
    if (/^\s/g.test(value)) {
      value = value.replace(/^\s+/, "");
    }

    if (tag === "roomType") {
      setStepForm({ ...stepForm, [tag]: value, numberOfBeds: "" });
      // setBeds([]);
    } else {
      if (tag === "numberOfBeds") {
        if (value) {
          value = Math.floor(parseInt(value));
        }
        // setBeds([]);
      }

      setStepForm({ ...stepForm, [tag]: value });
    }
  };

  const hasEmptyValues = () => {
    console.log("HasEmptyValueCheckDraft 1", formData.sleepingArrangements);
    const errors = [];
    if (
      formData &&
      formData.sleepingArrangements &&
      formData.sleepingArrangements.length > 0
    ) {
      //   for (const row of formData.sleepingArrangements) {
      //     for (const key in row) {
      //       if (!row[key]) {
      //         return true;
      //       }
      //     }
      //     if (row.beds.length > 0) {
      //       for (const bed of beds) {
      //         if (bed.bedNumber.trim().length === 0) {
      //           return true;
      //         }
      //       }
      //     } else if (row.numberOfBeds !== row.beds.length) {
      //       toast.error("No. of bed must be equal");
      //       return true;
      //     } else {
      //       toast.error("Please add room");
      //       return true;
      //     }
      //   }
      //   return false;
      
      const rooms = formData.sleepingArrangements;
      rooms.forEach((room) => {
        for (const key in room) {
          if (room.numberOfBathroom === 0) {
            continue;
          }
          if (!room[key] && key != "coldbedManualBlockedDate") {
            errors.push({ error: "Missing required field - "+key, errorType: "room" });  
          }
        }
        if (room.numberOfBeds !== room.beds.length) {
          errors.push({ error: "No. of bed must be equal.", errorType: "bed" });
        }
        if (formData.rentalType === "Crashpad") {
          room.beds.forEach((bed) => {
            if (bed.bedNumber.trim().length === 0) {
              errors.push({
                error: "Bed number is required",
                errorType: "bed",
              });
            }
          });
        }
      });
    } else {
      errors.push({ error: "Atleast 1 room is required", errorType: "room" });
    }

    return errors;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (isEditBed) {
      return toast.info("Please save bed Information.");
    }

    console.log(beds, "beds data");
    setFormData({
      ...formData,
      sleepingArrangements: roomList,
    });
    // setBeds([]);
    let errors = hasEmptyValues();
    if (errors.length > 0) {
      toast.info(errors[0].error, { toastId: "bed" });
      return;
    }

    if (formData.rentalType === "Crashpad") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      onNext();
    } else if (roomList.length && formData.rentalType === "Entire Place") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      onNext();
    } else {
      return;
    }
  };

  const saveAsdraft = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      sleepingArrangements: roomList,
    });
    setBeds([]);
    // if (roomList.length) {
    saveAsDraft();
    // }
  };

  const handleBathroom = ({ type }) => {
    if (type === "minus") {
      if (numberOfBathroom === 0) {
        return;
      } else setBathroom(numberOfBathroom - 0.5);
    } else {
      setBathroom(numberOfBathroom + 0.5);
    }
  };

  return (
    <div className={className}>
      <div className=" ">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            7
          </span>{" "}
          Sleeping Arrangements
        </h4>
        <div className=" row col-lg-12">
          <div className="card">
            <div className="card-body">
              {/* <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ Add all rooms and beds where guests sleep including those that
                  are currently occupied
                </p> */}

              <div className="table-responsive">
                <table className="table header-border table-responsive-sm">
                  <thead>
                    <tr>
                      <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                        Room No.
                      </td>
                      <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                        Room Name
                      </td>
                      {formData.rentalType === "Crashpad" && (
                        <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                          Room Type
                        </td>
                      )}
                      {formData.rentalType === "Crashpad" && (
                        <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                          Gender
                        </td>
                      )}
                      {formData.rentalType === "Crashpad" && (
                        <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                          Crew Type
                        </td>
                      )}
                      <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                        No. of Bathrooms{" "}
                      </td>
                      <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                        No. of Beds{" "}
                      </td>
                      <td className=" tw-text-sm md:tw-text-lg tw-font-medium">
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {roomList.map((curr, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{curr.roomNumber}</td>
                          <td>{curr.roomName}</td>
                          {formData.rentalType === "Crashpad" && (
                            <td>
                              {curr.roomType ? (
                                curr.roomType
                              ) : (
                                <span className="text-danger">
                                  This field is required.
                                </span>
                              )}
                            </td>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <td>
                              {curr.genderType ? (
                                curr.genderType
                              ) : (
                                <span className="text-danger">
                                  This field is required.
                                </span>
                              )}
                            </td>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <td>
                              {curr.crewType ? (
                                curr.crewType
                              ) : (
                                <span className="text-danger">
                                  This field is required.
                                </span>
                              )}
                            </td>
                          )}
                          <td>{curr.numberOfBathroom}</td>
                          <td>{curr.beds.length}</td>
                          <td>
                            <button
                              onClick={() => editSleepingArrangement(curr, ind)}
                              type="button"
                              className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none"
                            >
                              <BsPencilSquare size={17} />
                            </button>

                            <button
                              onClick={() =>
                                deleteSleepingArrangement(curr, ind)
                              }
                              type="button"
                              className="mx-1 tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none"
                            >
                              <BsTrash size={17} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="tw-p-5">
                  <h4 className="tw-text-center">
                    {roomList.length > 0
                      ? "Add More Rooms"
                      : "No Room Added yet"}
                  </h4>
                  <button
                    type="button"
                    onClick={() => addSleepingArrangement()}
                    className="tw-mx-auto tw-h-16 tw-w-16 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                    // data-bs-toggle="modal"
                    // data-bs-target=".bd-example-modal-lg"
                  >
                    <BsPlusLg size={22} />
                  </button>
                </div>
              </div>
              <div
                className="modal  bd-example-modal-lg"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
                id="addRoomModal"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add Room</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <form onSubmit={handleRoom}>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Room Number
                              </label>
                              <input
                                onChange={(e) =>
                                  handleStep("roomNumber", e.target.value)
                                }
                                value={
                                  stepForm["roomNumber"]
                                    ? stepForm["roomNumber"]
                                    : ""
                                }
                                type="text"
                                name="roomNumber"
                                className="form-control"
                                placeholder="Room Number"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Room Name
                              </label>
                              <input
                                onChange={(e) =>
                                  handleStep("roomName", e.target.value)
                                }
                                value={
                                  stepForm["roomName"]
                                    ? stepForm["roomName"]
                                    : ""
                                }
                                type="text"
                                name="roomName"
                                className="form-control"
                                placeholder="Room Name"
                                required
                              />
                            </div>
                          </div>

                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label">Room Type</label>
                                <select
                                  onChange={(e) =>
                                    handleStep("roomType", e.target.value)
                                  }
                                  value={
                                    stepForm["roomType"]
                                      ? stepForm["roomType"]
                                      : ""
                                  }
                                  name="roomType"
                                  className="  form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Shared">Shared</option>
                                  <option value="Private">Private</option>
                                </select>
                              </div>
                            </div>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label">
                                  Gender Type
                                </label>
                                <select
                                  onChange={(e) =>
                                    handleStep("genderType", e.target.value)
                                  }
                                  value={
                                    stepForm["genderType"]
                                      ? stepForm["genderType"]
                                      : ""
                                  }
                                  className="form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Co-Ed">Co-Ed</option>
                                </select>
                              </div>
                            </div>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label"> Crew Type</label>
                                <select
                                  onChange={(e) =>
                                    handleStep("crewType", e.target.value)
                                  }
                                  value={
                                    stepForm["crewType"]
                                      ? stepForm["crewType"]
                                      : ""
                                  }
                                  className="form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Flight Attendant Only">
                                    Flight Attendant Only
                                  </option>
                                  <option value="Pilot Only">Pilot Only</option>
                                  <option value="Flight Attendant & Pilot Only">
                                    Flight Attendant & Pilot Only
                                  </option>
                                  <option value="All Airline Professionals">
                                    All Airline Professionals
                                  </option>
                                </select>
                              </div>
                            </div>
                          )}

                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="text-label form-label">
                                  Number of Beds
                                </label>
                                <input
                                  onChange={(e) => {
                                    let number = parseInt(e.target.value);
                                    if (
                                      (stepForm["roomType"] === "Shared" &&
                                        number <= 50 &&
                                        number >= 1) ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    } else if (
                                      (stepForm["roomType"] === "Private" &&
                                        parseInt(e.target.value) <= 1 &&
                                        parseInt(e.target.value) >= 1) ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  value={
                                    stepForm["numberOfBeds"]
                                      ? stepForm["numberOfBeds"]
                                      : ""
                                  }
                                  maxLength={2}
                                  min={1}
                                  max={50}
                                  name="bedNumbers"
                                  className="form-control"
                                  placeholder="Number of beds"
                                  required
                                  disabled={!stepForm["roomType"]}
                                  type="text"
                                  pattern="[0-9]*"
                                />
                              </div>
                            </div>
                          )}

                          {formData.rentalType === "Entire Place" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="text-label form-label">
                                  Number of Beds
                                </label>
                                <input
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) >= 1 ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  value={
                                    stepForm["numberOfBeds"]
                                      ? stepForm["numberOfBeds"]
                                      : ""
                                  }
                                  min={1}
                                  name="bedNumbers"
                                  className="form-control"
                                  placeholder="Number of beds"
                                  required
                                  type="text"
                                  pattern="[0-9]*"
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Number of Bathrooms
                              </label>
                              <div className="tw-flex tw-space-x-2 tw-items-center">
                                <span
                                  onClick={() =>
                                    handleBathroom({ type: "minus" })
                                  }
                                  className="tw-cursor-pointer tw-flex-shrink-0  tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center"
                                >
                                  <AiOutlineMinus
                                    size={18}
                                    className="tw-text-gray-700"
                                  />
                                </span>
                                <div className="tw-w-44 tw-flex-grow">
                                  <input
                                    readOnly
                                    value={numberOfBathroom}
                                    type="text"
                                    name="numberOfBathroom"
                                    className="form-control"
                                    placeholder="Number of Bathroom"
                                    required
                                  />
                                </div>
                                <span
                                  onClick={() =>
                                    handleBathroom({ type: "plus" })
                                  }
                                  className="tw-cursor-pointer tw-flex-shrink-0  tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center"
                                >
                                  <AiOutlinePlus
                                    size={18}
                                    className="tw-text-gray-700"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 mb-2">
                                <div>
                                  <label className="form-label">
                                    {" "}
                                    numberOfBathroom Type
                                  </label>
                                  <select
                                    onChange={(e) =>
                                      handleStep("bathroom_type", e.target.value)
                                    }
                                    value={stepForm["bathroom_type"]}
                                    className=" form-control wide"
                                  >
                                    <option value="">Select</option>
                                    <option value="fully"> Fully</option>
                                    <option value="half">Half</option>
                                    <option value="No numberOfBathroom">
                                      No numberOfBathroom
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                        </div>
                        <h5 className="modal-title tw-my-4">Add Bed</h5>

                        <div className="table-responsive">
                          <table className="table table-responsive-sm">
                            <thead>
                              <tr>
                                {formData.rentalType === "Crashpad" && (
                                  <th className="tw-text-sm md:tw-text-base">
                                    Bed Number
                                  </th>
                                )}
                                <th className="tw-text-sm md:tw-text-base">
                                  Bed Type
                                </th>
                                {formData.rentalType === "Crashpad" &&
                                  (formData.bookingType === "Hot bed" ||
                                    formData.bookingType === "Flexible") && (
                                    <th className="tw-text-sm md:tw-text-base">
                                      Nightly Price
                                    </th>
                                  )}
                                {formData.rentalType === "Crashpad" &&
                                  (formData.bookingType === "Cold bed" ||
                                    formData.bookingType === "Flexible") && (
                                    <th className="tw-text-sm md:tw-text-base">
                                      Monthly Price{" "}
                                      <Tippy
                                        arrow={false}
                                        interactive
                                        content={
                                          <p className=" tw-text-xs md:tw-text-sm tw-text-gray-500 tw-shadow text-wrap  tw-p-2 ">
                                            Enter price for Reserve crewmembers.
                                            Lineholder discount can be added in
                                            Prices and Fees tab if applicable
                                          </p>
                                        }
                                        trigger="mouseenter"
                                        theme="light"
                                        className="tw-bg-white "
                                      >
                                        <span className="ml-2 tw-lowercase tw-cursor-pointer">
                                          ⓘ
                                        </span>
                                      </Tippy>{" "}
                                    </th>
                                  )}
                                <th className="tw-text-sm md:tw-text-base">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderBedlist()}

                              {beds.length < stepForm.numberOfBeds && (
                                <tr>
                                  {formData.rentalType === "Crashpad" && (
                                    <th>
                                      <div>
                                        <input
                                          onChange={(e) => {
                                            if (/^\s/g.test(e.target.value)) {
                                              e.target.value = e.target.value.replace(
                                                /^\s+/,
                                                ""
                                              );
                                            }
                                            setBedNumber(e.target.value);
                                          }}
                                          value={bedNumber}
                                          type="text"
                                          className="form-control"
                                          placeholder="Bed Number"
                                          required
                                        />
                                      </div>
                                    </th>
                                  )}
                                  <th>
                                    <div>
                                      <select
                                        name="bed_type"
                                        onChange={(e) =>
                                          setBedType(e.target.value)
                                        }
                                        value={bedType}
                                        className="form-control wide"
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="King">King</option>
                                        <option value="Queen">Queen</option>
                                        <option value="Twin">Twin</option>
                                        <option value="Couch">Couch</option>
                                        <option value="Top bunk">
                                          Top bunk
                                        </option>
                                        <option value="Bottom bunk">
                                          Bottom bunk
                                        </option>
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </th>

                                  {formData.rentalType === "Crashpad" &&
                                    (formData.bookingType === "Hot bed" ||
                                      formData.bookingType === "Flexible") && (
                                      <th>
                                        <div>
                                          <input
                                            onChange={(e) => {
                                              if (
                                                parseInt(e.target.value) >= 1 ||
                                                e.target.value == ""
                                              ) {
                                                setNightPrice(
                                                  e.target.value
                                                    ? Math.floor(
                                                        parseInt(e.target.value)
                                                      )
                                                    : ""
                                                );
                                              }
                                            }}
                                            value={perNightPrice}
                                            placeholder="Per night price"
                                            maxLength={9}
                                            min={1}
                                            max={999999999}
                                            className="form-control"
                                            type="text"
                                            required
                                          />
                                        </div>
                                      </th>
                                    )}
                                  {formData.rentalType === "Crashpad" &&
                                    (formData.bookingType === "Cold bed" ||
                                      formData.bookingType === "Flexible") && (
                                      <th>
                                        <div>
                                          <input
                                            onChange={(e) => {
                                              if (
                                                parseInt(e.target.value) >= 1 ||
                                                e.target.value == ""
                                              ) {
                                                setMonthPrice(
                                                  e.target.value
                                                    ? Math.floor(
                                                        parseInt(e.target.value)
                                                      )
                                                    : ""
                                                );
                                              }
                                            }}
                                            value={perMonthPrice}
                                            type="number"
                                            className="form-control"
                                            placeholder="Per month price"
                                            maxLength={9}
                                            min={1}
                                            max={999999999}
                                            required
                                          />
                                        </div>
                                      </th>
                                    )}

                                  <th>
                                    <div>
                                      <button
                                        onClick={handleBed}
                                        type="button"
                                        className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                                      >
                                        <BsPlusLg size={20} />
                                      </button>
                                    </div>
                                  </th>
                                </tr>
                              )}

                              {/* {isEditBed && (
                                      <tr>
                                        {formData.rentalType === "Crashpad" && (
                                          <th>
                                            <div>
                                              <input
                                                onChange={(e) => {
                                                  setBedNumber(e.target.value);
                                                }}
                                                value={bedNumber}
                                                type="text"
                                                className="form-control"
                                                placeholder="Bed Number"
                                                required
                                              />
                                            </div>
                                          </th>
                                        )}
                                        <th>
                                          <div>
                                            <select
                                              name="bed_type"
                                              onChange={(e) =>
                                                setBedType(e.target.value)
                                              }
                                              value={bedType}
                                              className="form-control wide"
                                              required
                                            >
                                              <option value="">Select</option>
                                              <option value="King">King</option>
                                              <option value="Queen">Queen</option>
                                              <option value="Top bunk">
                                                Top bunk
                                              </option>
                                              <option value="Bottom bunk">
                                                Bottom bunk
                                              </option>
                                            </select>
                                          </div>
                                        </th>
  
                                        {formData.rentalType === "Crashpad" &&
                                          (formData.bookingType === "Hot bed" ||
                                            formData.bookingType ===
                                            "Flexible") && (
                                            <th>
                                              <div>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(e.target.value) >=
                                                      1 ||
                                                      e.target.value == ""
                                                    ) {
                                                      setNightPrice(
                                                        e.target.value
                                                          ? Math.floor(
                                                            parseInt(
                                                              e.target.value
                                                            )
                                                          )
                                                          : ""
                                                      );
                                                    }
                                                  }}
                                                  value={perNightPrice}
                                                  placeholder="Per night price"
                                                  maxLength={9}
                                                  min={1}
                                                  max={999999999}
                                                  className="form-control"
                                                  type="text"
                                                  required
                                                />
                                              </div>
                                            </th>
                                          )}
                                        {formData.rentalType === "Crashpad" &&
                                          (formData.bookingType === "Cold bed" ||
                                            formData.bookingType ===
                                            "Flexible") && (
                                            <th>
                                              <div>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(e.target.value) >=
                                                      1 ||
                                                      e.target.value == ""
                                                    ) {
                                                      setMonthPrice(
                                                        e.target.value
                                                          ? Math.floor(
                                                            parseInt(
                                                              e.target.value
                                                            )
                                                          )
                                                          : ""
                                                      );
                                                    }
                                                  }}
                                                  value={perMonthPrice}
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Per month price"
                                                  maxLength={9}
                                                  min={1}
                                                  max={999999999}
                                                  required
                                                />
                                              </div>
                                            </th>
                                          )}
  
                                        <th>
                                          <div>
                                            <button
                                              onClick={updatedBed}
                                              type="button"
                                              className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                                            >
                                              <BsCheck2All size={20} />
                                            </button>
                                          </div>
                                        </th>
                                      </tr>
                                    )} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger light"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-secondary">
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2  tw-mb-2"
        >
          Prev
        </button>
        <button
          type="button"
          onClick={handleNextStep}
          className="btn btn-secondary mx-2 tw-mb-2"
        >
          Next
        </button>
        <button
          type="button"
          disabled={draftRequesting}
          onClick={saveAsdraft}
          className="btn btn-secondary mx-2 tw-mb-2"
        >
          {draftRequesting ? "Adding ..." : "Save as Draft"}
        </button>
      </div>
    </div>
  );
};
