import React, { useState } from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import constant from "../../../_config/constant";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
    ConnectComponentsProvider,
    ConnectAccountOnboarding
  } from "@stripe/react-connect-js";
import {
    authHeader
  } from "../../../_helpers";

var Modal = require("react-bootstrap-modal");


export const PayoutMethod = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  saveAsDraft,
  draftRequesting,
  hostDetail,
  setIspaymentConfirm,
  isPaymentConfirm,
  setIsButtonDisable,
  isButtonDisable
}) => {
  const [stepForm, setStepForm] = useState({
    perNightPrice: formData.perNightPrice,
    extraChargePerGuest: formData.extraChargePerGuest,
    perMonthPrice: formData.perMonthPrice,
    cleaningFee: formData.cleaningFee,
    securityDeposite: formData.securityDeposite,
    lineholderDiscountType: formData.lineholderDiscountType,
    lineholderDiscount: formData.lineholderDiscount,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
    setFormData({ ...formData, ...stepForm, [tag]: value });
  };
  const handleNextStep = async (e) => {
    e.preventDefault();

    const response = await fetch(constant.apiUrl+'/stripe-account-update-payout', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
    if (!response.ok) {
      // Handle errors on the client side here
      const {error} = await response.json();
      console.error('An error occurred: ', error);
      // document.querySelector('#error').removeAttribute('hidden');
      return undefined;
    } else {
        
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIspaymentConfirm(true)
      onNext();
    }
  };

  const [hideButtons, setHideButtons] = useState(false);
  //Stripe Code 
  
  const [stripeConnectInstance] = useState(() => {
    console.log("stripehere");
    
    const fetchClientSecret = async () => {
      //return "accs_secret__RQTyaKyXymHnukgVBBBMxRu8hbHUtB057FD0YB2eItUjzyw";
      // Fetch the AccountSession client secret
      const response = await fetch(constant.apiUrl+'/stripe-account-create', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.error('An error occurred: ', error);
        // document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const data = await response.json();
        console.log("clientsecre here "+data.data.client_secret )
        return data.data.client_secret;
      }
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      //replace this with env var while pushing code note-deploy
      publishableKey:  process.env.REACT_APP_STRIPE_API_KEY,// "pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz",
      fetchClientSecret: fetchClientSecret,
    })
  });

  
  //Stripe Code

  const updateStripeUserAccout = async () => {
    const response = await fetch(constant.apiUrl+'/stripe-account-update-payout', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
    if (!response.ok) {
      // Handle errors on the client side here
      const {error} = await response.json();
      console.error('An error occurred: ', error);
      // document.querySelector('#error').removeAttribute('hidden');
      return undefined;
    } else {
        
      window.scrollTo({ top: 0, behavior: "smooth" });
      setIspaymentConfirm(true)
      onNext();
        
    }
    
    // update user flag 
    // const response = await fetch(constant.apiUrl+'/stripe-account-update-payout', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
    // if (!response.ok) {
    //   // Handle errors on the client side here
    //   const {error} = await response.json();
    //   console.error('An error occurred: ', error);
    //   // document.querySelector('#error').removeAttribute('hidden');
    //   return undefined;
    // } else {
        
    //     hostDetail.payoutVerification = 1 ; 
    //     window.scrollTo({ top: 0, behavior: "smooth" });
    //     onNext();
        
    // }
  }
  const saveAsdraft = (e) => {
    // if (
    //   stepForm.perNightPrice <= 0 ||
    //   stepForm.perMonthPrice <= 0 ||
    //   stepForm.securityDeposite <= 0 ||
    //   stepForm.extraChargePerGuest <= 0
    // ) {
    //   toast.info("Prices cannot be zero or less.");
    //   return;
    // }
    setFormData({ ...formData, ...stepForm });
    saveAsDraft();
  };
  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div>
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              10
            </span>{" "}
            Payout Method
          </h4>
          <div className="col-lg-6 mb-2"></div>
          <div className="row">
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding
                 onExit={() => {
                    console.log("The account has exited onboarding");
                    //setHideButtons(true);
                    //setIsButtonDisable(true)
                    updateStripeUserAccout();
                    // setTimeout(() => {
                    //     updateStripeUserAccout();  // Reloads the page
                    // }, 1000);  // 3000 milliseconds (3 seconds)
                }}
                // Optional: make sure to follow our policy instructions above
                // fullTermsOfServiceUrl="{{URL}}"
                // recipientTermsOfServiceUrl="{{URL}}"
                // privacyPolicyUrl="{{URL}}"
                // skipTermsOfServiceCollection={false}
                // collectionOptions={{
                //   fields: 'eventually_due',
                //   futureRequirements: 'include',
                // }}
                // onStepChange={(stepChange) => {
                //   console.log(`User entered: ${stepChange.step}`);
                // }}
                />
            </ConnectComponentsProvider>
          </div>
        </div>
        <div className="payout-property-margin"  style={{ marginTop: "200px !important" }}>
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2  tw-mb-2"
          >
            Prev
          </button>

          <button
            disabled={!isPaymentConfirm}
            onClick={(e) => handleNextStep(e)}
            className="btn btn-secondary tw-mb-2"
          >
            Next
          </button>
          
        </div>
      </div>
    </form>
  );
};
