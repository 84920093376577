import React, { useEffect, useState } from "react";
import constant from "../../_config/constant";
import { Wrapper } from "./Wrapper";
import { ToastContainer } from "react-toastify";
import Individual from "./Individual";
import Company from "./Company";
import { bankService } from "../../_services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//import { ConnectAccountOnboarding, ConnectComponentsProvider , ConnectPayments } from "@stripe/react-stripe-js";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectAccountOnboarding
} from "@stripe/react-connect-js";
import {
  authHeader,
  // logout,
  basicAuthHeader,
} from "../../_helpers";




function UpdatePaymentInfo() {
  const [business_type, setBusiness_type] = useState("individual");

  const [stripeDetails, setStripeDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateStripeDetails, setUpdateStripeDetails] = useState(new Date());
  const [updateStripeSession, setUpdateStripeSession] = useState(new Date());

  const history = useHistory();

   //Stripe Code 
  
   const [stripeConnectInstance] = useState(() => {
    console.log("stripehere");
    
    const fetchClientSecret = async () => {
      //return "accs_secret__RQTyaKyXymHnukgVBBBMxRu8hbHUtB057FD0YB2eItUjzyw";
      // Fetch the AccountSession client secret
      const response = await fetch(constant.apiUrl+'/stripe-account-create', { method: "GET" , headers: { ...authHeader(), "Content-Type": "application/json" }});
     
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.error('An error occurred: ', error);
        // document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const data = await response.json();
        console.log("clientsecre here "+data.data.client_secret )
        
        
        //document.querySelector('#error').setAttribute('hidden', '');
        return data.data.client_secret;
      }
    }

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      //replace this with env var while pushing code note-deploy
      publishableKey:  process.env.REACT_APP_STRIPE_API_KEY,// "pk_test_51Iu3xqBryti39zXvQNb9k2efXmKmEpiKqyXcS13X45bqOtmk9UCKzRKZX5CEuOUl0WArEm6W0SJkLIhFxOIf7EsX006Ltaz5Gz",",
      fetchClientSecret: fetchClientSecret,
    })
  });

  
  //Stripe Code

  useEffect(() => {
    bankService
      .getStripeDetail()
      .then((res) => {
        if (res.data.business_type) {
          setBusiness_type(res.data.business_type);
        }
        setStripeDetails(res.data);
        setIsLoading(false);

        if (res.data.charges_enabled && res.data.payouts_enabled) {
          history.goBack();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
     
  }, [updateStripeDetails ]);

  

//   useEffect(() => {
//     console.log("here1")
//     bankService
//     .getStripeSession()
//     .then((res) => {
//       if (res.data) {
//        console.log("here"+res);
//       }
//     })
//     .catch(() => {
//       setIsLoading(false);
//     });
   
// }, [ updateStripeSession]);

  return (
    <Wrapper>
      <ToastContainer />
      {isLoading ? (
        "Loading.."
      ) : (
        <div className="col-lg-6  tw-space-y-3 tw-my-4">
           {/* Stripe Code start */}
           {/* <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectPayments />
      </ConnectComponentsProvider> */}
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountOnboarding
          onExit={() => {
            console.log("The account has exited onboarding");
            setTimeout(() => {
              window.location.reload();  // Reloads the page
            }, 3000);  // 3000 milliseconds (3 seconds)
          }}
          // Optional: make sure to follow our policy instructions above
          // fullTermsOfServiceUrl="{{URL}}"
          // recipientTermsOfServiceUrl="{{URL}}"
          // privacyPolicyUrl="{{URL}}"
          // skipTermsOfServiceCollection={false}
          // collectionOptions={{
          //   fields: 'eventually_due',
          //   futureRequirements: 'include',
          // }}
          // onStepChange={(stepChange) => {
          //   console.log(`User entered: ${stepChange.step}`);
          // }}
        />
    </ConnectComponentsProvider>
  
                  {/* Stripe Code end */}
          {/* <div className="mb-3">
            <label className="form-label">Business Type</label>
            <select
              value={business_type}
              onChange={(e) => {
                setBusiness_type(e.target.value);
              }}
              className="form-control  wide"
              name="business_type"
              required
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
          </div>

          {business_type === "individual" ? (
            <Individual
              setUpdateStripeDetails={setUpdateStripeDetails}
              stripeDetails={stripeDetails}
            />
          ) : business_type === "company" ? (
            <Company
              setUpdateStripeDetails={setUpdateStripeDetails}
              stripeDetails={stripeDetails}
            />
          ) : null} */}
        </div>
      )}
    </Wrapper>
  );
}

export default UpdatePaymentInfo;
