import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../../Navbar";
import TopBar from "../../topbar";
import Tippy from "@tippy.js/react";
import CustomModal from "../../ReactModal";
import { BsPlusLg, BsTrash, BsPencilSquare, BsCheck2All } from "react-icons/bs";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";
import { getData } from "../../../_helpers";
import { useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import Persona from "persona";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { getAddressByLatLng } from "../../../_helpers/GeoCoder";

import {
  AiOutlineInfoCircle,
  AiFillDelete,
  AiOutlinePlus,
  AiOutlineMinus,
} from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { BsPlusCircleFill } from "react-icons/bs";
import { nanoid } from "nanoid";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../../../layout/footer";
import {
  amenityService,
  safetyFeatureService,
  propertyService,
  userService,
  bankService,
} from "../../../_services";
import constant from "../../../_config/constant";
import { processMediaUrl } from "../../../_helpers/utils";
import { SleepingArrangements } from "./SleepingArrangements";
import { Wrapper } from "./Wrapper";
import { OTPInput } from "./OTPInput";
import { BasicDetailsForm } from "./BasicDetailsForm";
import { Details } from "./Details";
import { HouseRules } from "./HouseRules";
import { AddPhotos } from "./AddPhotos";
import { Amenities } from "./Amenities";
import { SafetyFeatures } from "./SafetyFeatures";
import { PricingAndFee } from "./PricingAndFee";
import { Terms } from "./Terms";
import { AddBankAccountForm } from "./AddBankAccountForm";
import { SecurityCheckPoints } from "./SecurityCheckPoints";
import { PayoutMethod } from "./PayoutMethod";
var Modal = require("react-bootstrap-modal");

export function AddProperty(props) {
 
  const [formStep, setFormStep] = useState(0);
  const [propertyId, setPropertyId] = useState("");
  const [formData, setFormData] = useState({});
  const [amenityList, setAmenityList] = useState([]);
  const [safetyFeatureList, setSafetyFeatureList] = useState([]);
  const [requesting, setRequest] = useState(false);
  const [draftRequesting, setDraftRequesting] = useState(false);
  const [hostDetail, setDetail] = useState({});
  const [apiCalled, setApiCalled] = useState(false);
  const [isPaymentConfirm, setIspaymentConfirm] = useState(hostDetail.payoutVerification);
  const [isButtonDisable, setIsButtonDisable] = useState(hostDetail.payoutVerification);
  const params = useParams();
  const handleNextStep = (step) => {
    // console.log(step);
    // if (step < 11) {
    //   setFormStep((step) => step + 1);
    // }
    if(hostDetail.payoutVerification == 1 )
      {
        console.log("step--11---", step);
        if (step < 9) {
          setFormStep((step) => step + 1);
        }
      }
      else
      {
        console.log("step--0---", step);
        if (step < 10) {
          setFormStep((step) => step + 1);
        }
      }
  };

  const handlePrevStep = (step) => {
    if (step > 0) {
      setFormStep((step) => step - 1);
    }
  };
  const handleFormSubmission = () => {
    setRequest(true);
    formData.propertyId = propertyId;
    propertyService.add(formData).then(
      (objS) => {
       // if (hostDetail && hostDetail.depositVerification) {
        if (true) {
          props.history.push("/properties");
        } else {
          toast.info("Please verify your bank account.");
         // props.history.push("/setting/payout-method");
        }
      },
      (error) => { }
    );
  };

  const saveAsDraft = () => {
    
    setDraftRequesting(true);
    formData.propertyId = propertyId;
    propertyService.saveAsDraft(formData).then(
      (objS) => {
        if (objS.status) {
          setPropertyId(objS.data.propertyId);
          toast.success(objS.message);
        } else {
          toast.error("Something went wrong.");
        }
        setDraftRequesting(false);
      },
      (error) => {
        toast.error("Something went wrong.");
        setDraftRequesting(false);
      }
    );
  };

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      event.preventDefault();
      document.body.classList.remove("modal-open");
      window.$("#addRoomModal").modal("hide");
      window.$(".modal-backdrop").remove();
    });
  }, []);

  useEffect(() => {
    amenityService.List({}).then(
      (objS) => {
        if (objS.status) setAmenityList(objS.data);
      },
      (error) => { }
    );
    safetyFeatureService.List({}).then(
      (objS) => {
        if (objS.status) setSafetyFeatureList(objS.data);
      },
      (error) => { }
    );
    userService.getDetail({}).then(
      (objS) => {
      
        if (objS.status) {
         
          setDetail(objS.data);
        }
      },
      (error) => { }
    );

    if (params && params.id) {
      setPropertyId(params.id);
      propertyService.getDetail(params.id).then(
        (objS) => {
          if (objS.status) {
            if (objS.data.status != "Draft") {
              props.history.push("/properties");
            }
            objS.data.location =
              objS.data.location &&
                objS.data.location[0] &&
                objS.data.location[1]
                ? { lat: objS.data.location[1], lng: objS.data.location[0] }
                : { lat: constant.lat, lng: constant.lng };
            setFormData({ ...objS.data });
          }
          setApiCalled(true);
        },
        (error) => {
          setApiCalled(true);
        }
      );
    } else {
      setApiCalled(true);
    }
  }, []);

  return (
    <Wrapper>
      <ToastContainer />
      {apiCalled && (
        <div className="row">
          {/* <form onSubmit={handleFormSubmission} enctype="multipart/form-data"> */}
          <BasicDetailsForm
            className={`${formStep === 0 ? "tw-block" : "tw-hidden"}`}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <Details
            className={`${formStep === 1 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <HouseRules
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 2 ? "tw-block" : "tw-hidden"}`}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <AddPhotos
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 3 ? "tw-block" : "tw-hidden"}`}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />

          <Amenities
            className={`${formStep === 4 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            amenityList={amenityList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <SafetyFeatures
            className={`${formStep === 5 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            safetyFeatureList={safetyFeatureList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <SleepingArrangements
            formData={formData}
            className={`${formStep === 6 ? "tw-block" : "tw-hidden"}`}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <PricingAndFee
            className={`${formStep === 7 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <Terms
            className={`${formStep === 8 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            requesting={requesting}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          {
            hostDetail.payoutVerification == 1 
            ? 
            <SecurityCheckPoints
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostDetail={hostDetail}
            setDetail={setDetail}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
            isPaymentConfirm={isPaymentConfirm}
          />
            : 

            <PayoutMethod
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostDetail={hostDetail}
            setDetail={setDetail}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
            setIspaymentConfirm={setIspaymentConfirm}
            setIsButtonDisable={setIsButtonDisable}
            isButtonDisable={isButtonDisable}
            isPaymentConfirm={isPaymentConfirm}
          />

          }
          {
            hostDetail.payoutVerification == 0
            ? 
            <SecurityCheckPoints
            className={`${formStep === 10 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostDetail={hostDetail}
            setDetail={setDetail}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
            isPaymentConfirm={isPaymentConfirm}
          />
            :
            <></>

          }

          {/* <PayoutMethod
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostDetail={hostDetail}
            setDetail={setDetail}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          />
          <SecurityCheckPoints
            className={`${formStep === 10 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostDetail={hostDetail}
            setDetail={setDetail}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            saveAsDraft={() => saveAsDraft()}
            draftRequesting={draftRequesting}
          /> */}
          
          {/* </form> */}
        </div>
      )}
    </Wrapper>
  );
}

export default AddProperty;
